import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Table, Button } from "react-bootstrap";
import { Modal, Typography, Box } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GoX } from "react-icons/go";

// Styles for the modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};
const Createbill = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [customer, setCustomer] = useState(null);

  // Function to format the date
  const formatDate = (date) => {
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  const { orderDetails, selectedAccount } = location.state || {};

  useEffect(() => {
    const fetchSettings = async () => {
      const token = localStorage.getItem("token");
      const usernameFromStorage = localStorage.getItem("username"); // Get username from localStorage
      console.log(usernameFromStorage); // Log it to verify

      if (usernameFromStorage) {
        setUsername(usernameFromStorage); // Set username in state if found
      }

      if (!selectedAccount) {
        console.error("Selected account is not available");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `https://luckytraders.bwsoft.in/api/settinglist/${selectedAccount}/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch settings");

        const data = await response.json();
        setSettings(data);
        console.log("Email:", data.email);
      } catch (error) {
        console.error("Error fetching settings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, [selectedAccount]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        if (!orderDetails || !orderDetails.customer_id) {
          setLoading(false);
          return;
        }

        const token = localStorage.getItem("token");
        const response = await fetch(
          `https://luckytraders.bwsoft.in/api/getcustomer/${orderDetails.customer_id}/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();

          setCustomer(data);
        } else {
          console.error("Failed to fetch settings");
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, [orderDetails]);

  // Conditionally render the order details only if available
  if (!orderDetails) {
    return <div>No order details available.</div>;
  }

  // Format currency function
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount);
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="container mt-4" id="tabelcontent">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Invoice</h1>
        <Button variant="primary" onClick={handlePrint}>
          Print
        </Button>
      </div>

      <div id="print-area">
        {loading ? (
          <p>Loading settings...</p>
        ) : settings ? (
          <div className="mb-4 text-center">
            <img
              src={settings.logo}
              alt="logo"
              className="quotationbillimg"
              style={{ maxWidth: "150px", marginBottom: "10px" }}
            />
            <h2>{settings.name}</h2>
            <h6>{settings.address}</h6>
            <p>Phone:{orderDetails.outlet_mobile} </p>
          </div>
        ) : (
          <p>No settings available for this account.</p>
        )}{" "}
        {loading ? (
          <p>Loading settings...</p>
        ) : settings ? (
          <div className="d-flex justify-content-between align-items-center">
            <h5>GSTIN :{settings.gst_no}</h5>

            <h5>Email :{settings.email}</h5>
          </div>
        ) : (
          <p>No settings available for this account.</p>
        )}
        <hr></hr>
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <p>Issued to:</p>
            <h5>{customer?.name || "N/A"}</h5>
            <p>{customer?.address || "N/A"}</p>
            <p>GST: {customer?.customer_gst_no || "N/A"}</p>
            <p>Email: {customer?.email || "N/A"}</p>
            <p>Mobile: {customer?.mobile || "N/A"}</p>
          </div>
          <div>
            <p>Shipping Address</p>
            <p>{customer?.delivery_name || "N/A"}</p>
            <p>{customer?.delivery_address || "N/A"}</p>
            <p>GST:{customer?.delivery_gst_no || "N/A"}</p>
          </div>

          <div className=" create-bill">
            <h5 className="mb-4">CASH BILL</h5>
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-start ">
                <p className="mb-1 billcreate-left">Invoice No</p>
                <p className="billcreate-leftdot">:</p>
                <p className="mb-1 justify-content-start">
                  <strong>{orderDetails.id}</strong>
                </p>
              </div>
              <div className="d-flex justify-content-start ">
                <p className="mb-1 justify-content-start billcreate-left">
                  Date
                </p>
                <p className="billcreate-leftdot">:</p>
                <p className="mb-1 justify-content-start">
                  <strong onClick={() => setIsCalendarOpen(true)}>
                    {formatDate(selectedDate)}
                  </strong>
                </p>

                {/* Material-UI Modal for the DatePicker */}
                <Modal
                  open={isCalendarOpen}
                  onClose={() => setIsCalendarOpen(false)}
                  aria-labelledby="modal-title"
                  aria-describedby="modal-description"
                >
                  <Box sx={style}>
                    <div className="d-flex justify-content-between">
                      <Typography id="modal-title" variant="h6" component="h2">
                        Select a Date
                      </Typography>
                      <Button
                        variant="danger"
                        onClick={() => setIsCalendarOpen(false)}
                        sx={{ mt: 2 }}
                      >
                        <GoX />
                      </Button>
                    </div>
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => {
                        setSelectedDate(date);
                        setIsCalendarOpen(false); // Close the modal on date selection
                      }}
                      dateFormat="dd/MM/yyyy"
                      todayButton="Today"
                      inline
                      style={{ width: "100%" }}
                    />
                  </Box>
                </Modal>
              </div>
              <div className="d-flex justify-content-start ">
                <p className="mb-1 billcreate-left">Payment Mode</p>
                <p className="billcreate-leftdot">:</p>
                <p className="mb-1 d-flex justify-content-start ">
                  <strong>Cash</strong>
                </p>
              </div>
              <div className="d-flex justify-content-start ">
                <p className="mb-1 billcreate-left">Sales Rep</p>
                <p className="billcreate-leftdot">:</p>
                <p className="mb-1 justify-content-start">
                  <strong>{username || "Loading..."}</strong>{" "}
                  {/* Display username */}
                </p>
              </div>
              <div className="d-flex justify-content-start ">
                <p className="mb-1 billcreate-left">Branch Number</p>
                <p className="billcreate-leftdot">:</p>
                <p className="mb-1 justify-content-start">
                  <strong>{orderDetails.outlet_mobile} </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <p>Branch ID: {selectedAccount}</p>
        <Table striped bordered hover style={{ margin: 0, padding: 0 }}>
          {/* Order Details Section */}
          <thead className="table-primary">
            <tr>
              <th colSpan="9" className="text-center">
                Order Details
              </th>
            </tr>
            <tr>
              <th>#</th>
              <th>Product Name</th>
              <th>HSN Code</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Gst</th>
              <th>Igst</th>
              <th>Discount</th>

              <th>Total</th>
            </tr>
          </thead>
          <tbody className="transparent-bg">
            {orderDetails.order_items.length > 0 ? (
              orderDetails.order_items.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>
                    {item.product_name} <br></br>
                    <small>{item.description}</small>
                  </td>

                  <td>{item.product_code}</td>
                  <td>{item.qty}</td>
                  <td>{formatCurrency(item.retail_price)}</td>
                  <td>{item.gst_percent}</td>
                  <td>{item.igst_percent}</td>
                  <td>
                    {item.discount_percentage !== "0.00" &&
                    item.discount_percentage !== "0"
                      ? `${item.discount_percentage}%`
                      : item.discount_amount !== "0.00" &&
                        item.discount_amount !== "0"
                      ? `₹${item.discount_amount}`
                      : "-"}
                  </td>

                  <td>{item.total_price}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" className="text-center">
                  No items in this order.
                </td>
              </tr>
            )}
          </tbody>
          {/* Tax Details Section */}

          {/* Bank Details Section */}
          <thead className="table-primary">
            <tr>
              <th colSpan="5" className="text-center">
                Bank Details
              </th>
              <th
                colSpan="4"
                rowSpan="2"
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
              >
                Summary
              </th>
            </tr>
            <tr>
              <th colSpan="2">Account Holder</th>
              <th colSpan="2">Details</th>
              <th>UPI</th>
            </tr>
          </thead>
          <tbody>
            {settings ? (
              <>
                <tr>
                  <td rowSpan={2} colSpan={2}>
                    Account Holder
                  </td>
                  <td rowSpan={2} colSpan={2}>
                    {settings.account_details.account_holder}
                  </td>
                  {/* <td rowSpan={6} className="text-center">
                    {settings.account_details.bar_code ? (
                      <img
                        src={settings.account_details.bar_code}
                        alt="Bar Code"
                        style={{ width: "150px" }}
                      />
                    ) : (
                      <p>No image available</p>
                    )}
                  </td> */}
                  <td
                    rowSpan={
                      orderDetails.discount_total &&
                      !isNaN(orderDetails.discount_total) &&
                      orderDetails.discount_total > 0
                        ? 6
                        : 5
                    }
                    className="text-center"
                  >
                    {settings.account_details.bar_code ? (
                      <img
                        src={settings.account_details.bar_code}
                        alt="Bar Code"
                        style={{ width: "150px" }}
                      />
                    ) : (
                      <p>No image available</p>
                    )}
                  </td>

                  <td
                    colSpan="2"
                    rowSpan={1}
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    SUB TOTAL
                  </td>
                  <td
                    rowSpan={1}
                    colSpan={2}
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    {formatCurrency(orderDetails.subtotal)}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    rowSpan={1}
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    GST
                  </td>
                  <td
                    rowSpan={1}
                    colSpan={2}
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    {formatCurrency(
                      isNaN(orderDetails.cgst_charge)
                        ? 0
                        : orderDetails.cgst_charge
                    )}
                  </td>
                </tr>

                {orderDetails.discount_total &&
                !isNaN(orderDetails.discount_total) &&
                orderDetails.discount_total > 0 ? (
                  <>
                    <tr>
                      <td rowSpan={2} colSpan={2}>
                        Account No
                      </td>
                      <td rowSpan={2} colSpan={2}>
                        {settings.account_details.account_no}
                      </td>
                      <td
                        colSpan="2"
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        IGST
                      </td>
                      <td
                        colSpan={2}
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        {formatCurrency(
                          isNaN(orderDetails.cgst_charge)
                            ? 0
                            : orderDetails.cgst_charge
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan="2"
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        Discount Amount
                      </td>
                      <td
                        colSpan={2}
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        {formatCurrency(orderDetails.discount_total)}
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td rowSpan={1} colSpan={2}>
                      Account No
                    </td>
                    <td rowSpan={1} colSpan={2}>
                      {settings.account_details.account_no}
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      IGST
                    </td>
                    <td
                      colSpan={2}
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {formatCurrency(
                        isNaN(orderDetails.igst_charge)
                          ? 0
                          : orderDetails.igst_charge
                      )}
                    </td>
                  </tr>
                )}

                <tr>
                  <td colSpan={2}>IFSC Code</td>
                  <td colSpan={2}>{settings.account_details.ifsc_code}</td>
                  <td
                    colSpan="2"
                    rowSpan={1}
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    Total Amount
                  </td>
                  <td
                    rowSpan={1}
                    colSpan={2}
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    {formatCurrency(orderDetails.grand_total)}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>Bank</td>
                  <td colSpan={2}>{settings.account_details.bank}</td>
                  <td
                    colSpan="2"
                    rowSpan={1}
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    Paid Amount
                  </td>
                  <td
                    rowSpan={1}
                    colSpan={2}
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    {formatCurrency(orderDetails.paid_amount)}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>Branch</td>
                  <td colSpan={2}>{settings.account_details.branch}</td>
                  <td>UPI ID : {settings.account_details.upi_id}</td>
                  <td
                    colSpan="2"
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    Return Change
                  </td>
                  <td
                    colSpan={2}
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    {formatCurrency(orderDetails.return_change)}
                  </td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan={3} className="text-center">
                  No bank details available for this account.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div>
          <p className="d-flex justify-content-end align-items-start">
            For Lucky traders
          </p>
        </div>
        <div
          className="d-flex  align-items-end justify-content-between "
          style={{ padding: "70px 0" }}
        >
          <p>Customer Signature</p>

          <p>{username} Prepared By</p>
          <p>Authorized Signature</p>
        </div>
      </div>
    </div>
  );
};

export default Createbill;
