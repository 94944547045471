import { Table, Button, Modal, ListGroup, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Category } from "@mui/icons-material";
import { FaChevronDown } from "react-icons/fa";
const Quotation = () => {
  const [invoiceDetails, setInvoiceDetails] = useState({
    items: [],
    discountRate: 0, // default discount is 0
  });

  const [productList, setProductList] = useState([]);
  const [filteredProductList, setFilteredProductList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [outletList, setOutletList] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [customerList, setCustomerList] = useState([]); // To store the list of customers
  const [selectedCustomer, setSelectedCustomer] = useState(null); // To store the selected customer
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [settinglist, setSettinglist] = useState([]);
  const [discounts, setDiscounts] = useState({}); // Track discounts for each item
  const [discountType, setDiscountType] = useState({});
  const [filteredCustomerList, setFilteredCustomerList] = useState([]);

  const [showDropdown, setShowDropdown] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [customerSearchTerm, setCustomerSearchTerm] = useState("");
  const [newCustomer, setNewCustomer] = useState({
    name: "",
    email: "",
    mobile: "",
    address: "",
    customer_gst_no: "",
    delivery_name: "",
    delivery_address: "",
    delivery_gst_no: "",
  });
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    // Fetch product list
    axios
      .get("https://luckytraders.bwsoft.in/api/productlist/", config)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setProductList(response.data);
          setFilteredProductList(response.data);
        }
      })
      .catch((error) => console.error("Error fetching products:", error));
    // Fetch outlet list
    axios
      .get("https://luckytraders.bwsoft.in/api/outletlist/", config)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setOutletList(response.data);
          console.log("Fetched outlets:", response.data); // Log the fetched outlets
        }
      })
      .catch((error) => console.error("Error fetching outlets:", error));

    setIsModalOpen(true);
  }, []);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);
    const filteredProducts = productList.filter(
      (product) =>
        product.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        product.code.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredProductList(filteredProducts);
  };

  const handleSelectProduct = (product) => {
    const newItem = {
      id: product.id,
      name: product.name,
      gst: product.gst, // This will come from the backend
      igst: product.igst, // This will come from the backend
      code: product.code || "N/A",
      price: parseFloat(product.retail_price) || 0,
      quantity: 1,
    };

    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      items: [...prevDetails.items, newItem],
    }));

    setSearchTerm("");
    setFilteredProductList(productList); // Reset filtered product list
  };

  const handleQuantityChange = (itemId, amount) => {
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      items: prevDetails.items.map((item) =>
        item.id === itemId
          ? { ...item, quantity: Math.max(1, item.quantity + amount) }
          : item
      ),
    }));
  };

  const handleDiscountChange = (e) => {
    const newDiscountRate = parseFloat(e.target.value) || 0; // Make sure to handle invalid inputs
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      discountRate: newDiscountRate,
    }));
  };

  const subtotal = invoiceDetails.items.reduce(
    (acc, item) => acc + Number(item.price || 0) * Number(item.quantity || 0),
    0
  );

  const totalGst = invoiceDetails.items.reduce(
    (acc, item) => acc + ((item.gst * item.price * item.quantity) / 100 || 0),
    0
  );
  const totalIgst = invoiceDetails.items.reduce(
    (acc, item) => acc + ((item.igst * item.price * item.quantity) / 100 || 0),
    0
  );

  const discountAmount = (subtotal * invoiceDetails.discountRate) / 100;
  const totalPayable = subtotal + totalIgst + totalGst - discountAmount;

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleSelectOutlet = (outlet) => {
    setSelectedOutlet(outlet); // Ensure this updates correctly
    localStorage.setItem("selectedOutletId", outlet.id); // Store the selected outlet in localStorage
    handleCloseModal(); // Close the modal after selecting the outlet
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    // Fetch the customer list
    axios
      .get("https://luckytraders.bwsoft.in/api/customerlist/", config)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setCustomerList(response.data);
        }
      })
      .catch((error) => console.error("Error fetching customers:", error));
  }, []);
  useEffect(() => {
    const fetchSettingList = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(
          "https://luckytraders.bwsoft.in/api/settinglist/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch settings");

        const data = await response.json();
        console.log(data); // Log to check the structure
        setSettinglist(Array.isArray(data) ? data : []); // Ensure it's an array
      } catch (error) {
        console.error("Error fetching setting list:", error);
      }
    };

    fetchSettingList();
  }, []);

  const handleSubmit = () => {
    console.log("Selected Customer:", selectedCustomer);

    const token = localStorage.getItem("token");
    const outletId = selectedOutlet ? selectedOutlet.id : null;
    const customerId = selectedCustomer ? selectedCustomer.id : null;

    if (!outletId || !customerId) {
      alert("Please select both an outlet and a customer.");
      return;
    }

    const overallSubtotal = invoiceDetails.items
      .reduce((acc, item) => acc + item.price * item.quantity, 0)
      .toFixed(2);

    const postData = {
      customer: customerId,
      // customer: selectedCustomer,
      outlet_id: outletId,
      account_id: selectedAccount.id,
      total_price: totalPayable,
      products: invoiceDetails.items.map((item) => ({
        product: {
          name: item.name,
          code: item.code,
          gst: item.gst,
          igst: item.igst,
          retail_price: item.price.toFixed(2),
        },
        quantity: item.quantity,
        total_price: (item.price * item.quantity).toFixed(2),
      })),
    };

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .post("https://luckytraders.bwsoft.in/api/quotations/", postData, config)
      .then((response) => {
        console.log("Posted Data:", postData); // Log postData to the console
        alert(response.data.message); // Success message

        // Pass both postData and response data to the Quotationbill route
        navigate("/Quotationbill", {
          state: {
            quotationData: postData,
            responseData: response.data, // Add response data
          },
        });
      })
      .catch((error) => {
        console.error("Error creating quotation:", error);
        alert("Failed to create quotation.");
      });
  };

  const handleRemoveItem = (id) => {
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      items: prevDetails.items.filter((item) => item.id !== id),
    }));
  };

  const updateQuantity = (itemId, value) => {
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      items: prevDetails.items.map((item) =>
        item.id === itemId ? { ...item, quantity: value } : item
      ),
    }));
  };

  const handlePriceChange = (itemId, newPrice) => {
    // Update the price in the state
    setInvoiceDetails((prev) => {
      return {
        ...prev,
        items: prev.items.map((item) =>
          item.id === itemId ? { ...item, price: newPrice } : item
        ),
      };
    });
  };
  const handleproductDiscountChange = (itemId, type, value) => {
    setDiscounts((prev) => ({
      ...prev,
      [itemId]: { type, value },
    }));
  };
  const toggleDiscountType = (itemId) => {
    setDiscountType((prev) => ({
      ...prev,
      [itemId]: prev[itemId] === "percentage" ? "price" : "percentage",
    }));
  };
  const handleSearchCustomer = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setCustomerSearchTerm(searchValue);

    if (searchValue === "") {
      setFilteredCustomerList([]);
      return;
    }

    const filteredCustomers = customerList.filter(
      (customer) =>
        customer.name.toLowerCase().includes(searchValue) ||
        customer.mobile.includes(searchValue)
    );

    setFilteredCustomerList(filteredCustomers);
  };

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer.id); // Set selected customer's ID
    setCustomerSearchTerm(`${customer.name} - ${customer.mobile}`);
    setFilteredCustomerList([]); // Reset dropdown
    setShowDropdown(false);
  };
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handleNewCustomerChange = (e) => {
    const { name, value } = e.target;
    setNewCustomer((prev) => ({ ...prev, [name]: value }));
  };

  const addNewCustomer = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        "https://luckytraders.bwsoft.in/api/customer/create/",
        newCustomer,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setCustomerList((prev) => [...prev, response.data.customer_data]);
      setCustomerSearchTerm("");
      setFilteredCustomerList([]);
      closeModal();
    } catch (error) {
      console.error("Error adding customer:", error);
    }
  };
  const handleSameAsBilling = (e) => {
    if (e.target.checked) {
      setNewCustomer((prev) => ({
        ...prev,
        delivery_name: prev.name,
        delivery_address: prev.address,
        delivery_gst_no: prev.customer_gst_no,
      }));
    } else {
      setNewCustomer((prev) => ({
        ...prev,
        delivery_name: "",
        delivery_address: "",
        delivery_gst_no: "",
      }));
    }
  };
  return (
    <div className="bill-template" id="tabelcontent">
      <h1 className="text-center">Quotation</h1>
      <h2 className="text-center">
        {selectedOutlet ? selectedOutlet.name : "No outlet selected"}
      </h2>
      <div className="bill-header">
        <div className="product-search">
          <div
            style={{ position: "relative", width: "100%", maxWidth: "400px" }}
          >
            <input
              type="text"
              id="product-search"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Type product name or code..."
              style={{
                width: "100%",
                padding: "5px 40px 5px 15px", // Padding for space around the icon
                border: "2px solid #ddd",
                borderRadius: "8px",
                fontSize: "16px",
              }}
            />
            <span
              style={{
                position: "absolute",
                right: "5px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                color: "#999",
              }}
            >
              🔍{" "}
            </span>
          </div>

          {searchTerm && filteredProductList.length > 0 && (
            <ul className="product-dropdown">
              {filteredProductList.map((product) => (
                <li
                  key={product.id}
                  onClick={() => handleSelectProduct(product)}
                  className="dropdown-item"
                >
                  {product.name} - ₹{product.retail_price || 0}
                </li>
              ))}
            </ul>
          )}
        </div>
        {/* <div className="customer-search">
          <div
            style={{ position: "relative", width: "100%", maxWidth: "400px" }}
          >
            <input
              type="text"
              value={customerSearchTerm}
              onChange={handleSearchCustomer}
              placeholder="customer name or mobile..."
              style={{
                width: "100%",
                padding: "10px 40px 10px 15px",
                border: "2px solid #ddd",
                borderRadius: "8px",
                fontSize: "16px",
              }}
            />
            <span
              style={{
                position: "absolute",
                right: "15px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                color: "#999",
              }}
            ></span>
          </div>

          {showDropdown &&
            customerSearchTerm &&
            filteredCustomerList.length > 0 && (
              <ul className="product-dropdown">
                {filteredCustomerList.map((customer) => (
                  <li
                    key={customer.id}
                    onClick={() => handleSelectCustomer(customer)}
                    className="dropdown-item"
                    style={{
                      padding: "10px",
                      cursor: "pointer",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    {customer.name} - {customer.mobile}
                  </li>
                ))}
              </ul>
            )}

          {showDropdown &&
            customerSearchTerm &&
            filteredCustomerList.length === 0 && (
              <ul className="product-dropdown">
                <li
                  onClick={openModal}
                  style={{
                    padding: "10px",
                    cursor: "pointer",
                    borderBottom: "1px solid #ddd",
                    color: "#007bff",
                    backgroundColor: "#fff",
                  }}
                >
                  Add New Customer
                </li>
              </ul>
            )}
        </div> */}
        <div className="custom-select-container">
          <select
            id="customer-select"
            value={selectedCustomer ? selectedCustomer.id : ""}
            onChange={(e) => {
              const selectedCustomerId = e.target.value;
              const customer = customerList.find(
                (customer) => customer.id.toString() === selectedCustomerId
              );
              setSelectedCustomer(customer);
            }}
            className="custom-select"
          >
            <option value="" disabled>
              Select Customer
            </option>
            {customerList.map((customer) => (
              <option key={customer.id} value={customer.id}>
                {customer.name}
              </option>
            ))}
          </select>
        </div>

        <div className="custom-select-container">
          <select
            value={selectedAccount ? selectedAccount.id : ""}
            onChange={(e) => {
              const selectedAccountId = e.target.value;
              const account = settinglist.find(
                (account) => account.id.toString() === selectedAccountId
              );
              setSelectedAccount(account);
            }}
            className="custom-select"
          >
            <option value="" disabled>
              Select Account
            </option>
            {settinglist.length > 0 ? (
              settinglist.map((account) => (
                <option key={account.id} value={account.id}>
                  {account.name}
                </option>
              ))
            ) : (
              <option disabled>No accounts available</option>
            )}
          </select>
        </div>

        <div>
          <Button onClick={handleOpenModal}>Change Outlet</Button>
        </div>
      </div>

      <Table striped bordered hover className="bill-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Product Name</th>
            <th>HSN Code</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Gst</th>
            <th>IGst</th>
            {/* <th>Discount</th> */}
            <th>Total</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {invoiceDetails.items.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.name}</td>
              <td>{item.code}</td>
              <td>
                <div className="quantity-control">
                  <button
                    className="quantity-button"
                    onClick={() =>
                      handleQuantityChange(item.id, item.quantity - 1)
                    }
                  >
                    -
                  </button>

                  <input
                    type="number"
                    value={item.quantity}
                    onChange={(e) => {
                      const newQuantity =
                        e.target.value === ""
                          ? ""
                          : parseInt(e.target.value, 10);
                      if (
                        newQuantity === "" ||
                        (!isNaN(newQuantity) && newQuantity >= 0)
                      ) {
                        updateQuantity(item.id, newQuantity); // Handles updating the temp value
                      }
                    }}
                    onBlur={(e) => {
                      // Restore a valid quantity on blur if input is empty
                      if (
                        e.target.value === "" ||
                        parseInt(e.target.value, 10) < 1
                      ) {
                        handleQuantityChange(item.id, 1);
                      }
                    }}
                    min="0"
                    className="quantity-input"
                  />

                  <button
                    className="quantity-button"
                    onClick={() =>
                      handleQuantityChange(item.id, item.quantity + 1)
                    }
                  >
                    +
                  </button>
                </div>
              </td>
              <td>
                <input
                  type="number"
                  value={item.price}
                  onChange={(e) =>
                    handlePriceChange(item.id, parseFloat(e.target.value))
                  }
                  onBlur={(e) => {
                    // Optionally validate price or apply default if invalid
                    if (
                      isNaN(parseFloat(e.target.value)) ||
                      parseFloat(e.target.value) < 0
                    ) {
                      handlePriceChange(item.id, 0); // Default to 0 if invalid
                    }
                  }}
                  style={{
                    width: "80%",
                    padding: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                  }}
                />
              </td>

              <td>{item.gst ? `${item.gst}%` : "0%"}</td>

              {/* IGST Percentage */}
              <td>{item.igst ? `${item.igst}%` : "0%"}</td>

              {/* <td>
                <div style={{ position: "relative" }}>
                  <input
                    type="text"
                    value={
                      discounts[item.id]?.value || "" // Discount value
                    }
                    onChange={(e) =>
                      handleproductDiscountChange(
                        item.id,
                        discountType[item.id] || "percentage",
                        e.target.value
                      )
                    }
                    placeholder={
                      discountType[item.id] === "price"
                        ? "Enter discount price"
                        : "Enter discount %"
                    }
                    style={{
                      width: "80%",
                      padding: "5px",
                      marginRight: "5px",
                    }}
                  />
                  <button
                    onClick={() => toggleDiscountType(item.id)}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      color: "#007bff",
                    }}
                  >
                    <FaChevronDown />
                  </button>
                </div>
                <small>
                  Using{" "}
                  {discountType[item.id] === "price" ? "Price" : "Percentage"}
                </small>
              </td> */}
              <td>
                ₹
                {(
                  item.price * item.quantity -
                  (discountType[item.id] === "percentage"
                    ? (item.price *
                        item.quantity *
                        (discounts[item.id]?.value || 0)) /
                      100
                    : discounts[item.id]?.value || 0) +
                  (item.price * item.quantity * (item.gst || 0)) / 100 +
                  (item.price * item.quantity * (item.igst || 0)) / 100
                ).toFixed(2)}
              </td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => handleRemoveItem(item.id)}
                >
                  X
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Table striped bordered hover className="custom-bill-summary-table">
        <thead>
          <tr>
            <th colSpan={4} className="table-title">
              Bill Summary
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="label">Total Purchase Quantity</td>

            <td>
              {invoiceDetails.items.reduce(
                (acc, item) => acc + item.quantity,
                0
              )}
            </td>
          </tr>
          <tr>
            <td className="label">Subtotal</td>
            <td>₹{subtotal.toFixed(2)}</td>
          </tr>
          <tr>
            {/* <td className="label">Discount Rate (%):</td>
            <td>
              <input
                type="text"
                id="discount"
                value={invoiceDetails.discountRate}
                onChange={handleDiscountChange}
                min="0"
                max="100"
                step="0.1"
              />
            </td> */}

            <td className="label">GST Total</td>
            <td>₹{totalGst.toFixed(2)}</td>
          </tr>
          <tr>
            {/* <td className="label">Discount ({invoiceDetails.discountRate}%)</td>
            <td>-₹{discountAmount.toFixed(2)}</td> */}
            <td className="label">IGST Total</td>
            <td>₹{totalIgst.toFixed(2)}</td>
          </tr>
          <tr>
            <td className="total-label">
              <strong>Total Payable</strong>
            </td>
            <td className="total-value">
              <strong>₹{totalPayable.toFixed(2)}</strong>
            </td>
          </tr>
        </tbody>
      </Table>

      <div className="bill-footer d-flex g-2 justify-content-evenly">
        <Button variant="danger">Cancel</Button>
        {/* <Button variant="danger"> Hold Bill</Button> */}
        <Button onClick={handleSubmit}>Submit Quotation</Button>
      </div>

      <Modal show={isModalOpen} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Outlet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {outletList.map((outlet) => (
              <ListGroup.Item
                key={outlet.id}
                action
                onClick={() => handleSelectOutlet(outlet)}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <strong>{outlet.name}</strong>
                <div>{outlet.address}</div>
                <div>Mobile: {outlet.mobile}</div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderRadius: "12px",
              padding: "15px",
              width: "450px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <h2 style={{ textAlign: "center", marginBottom: "5px" }}>
              Create Customer
            </h2>
            <form>
              <div>
                {/* <label>Name *</label> */}
                <input
                  type="text"
                  name="name"
                  value={newCustomer.name}
                  onChange={handleNewCustomerChange}
                  placeholder="Enter name"
                  required
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                />
              </div>
              <div>
                {/* <label>Email Address</label> */}
                <input
                  type="email"
                  name="email"
                  value={newCustomer.email}
                  onChange={handleNewCustomerChange}
                  placeholder="Enter email address"
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                />
              </div>
              <div>
                {/* <label>Mobile Number *</label> */}
                <input
                  type="text"
                  name="mobile"
                  value={newCustomer.mobile}
                  onChange={handleNewCustomerChange}
                  placeholder="Enter mobile number"
                  required
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                />
              </div>
              <div>
                {/* <label>Address</label> */}
                <textarea
                  name="address"
                  value={newCustomer.address}
                  onChange={handleNewCustomerChange}
                  placeholder="Enter address"
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                ></textarea>
              </div>
              <div>
                {/* <label>GST Number</label> */}
                <input
                  type="text"
                  name="customer_gst_no"
                  value={newCustomer.customer_gst_no}
                  onChange={handleNewCustomerChange}
                  placeholder="Enter GST number"
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                />
              </div>
              <h4 style={{ marginBottom: "5px" }}>Customer Delivery Address</h4>
              <div>
                <input
                  type="checkbox"
                  id="sameAsBilling"
                  onChange={handleSameAsBilling}
                />
                <label htmlFor="sameAsBilling">
                  Same as billing name & address
                </label>
              </div>
              <div>
                {/* <label>Delivery Name</label> */}
                <input
                  type="text"
                  name="delivery_name"
                  value={newCustomer.delivery_name}
                  onChange={handleNewCustomerChange}
                  placeholder="Enter delivery name"
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                />
              </div>
              <div>
                {/* <label>Delivery Address</label> */}
                <textarea
                  name="delivery_address"
                  value={newCustomer.delivery_address}
                  onChange={handleNewCustomerChange}
                  placeholder="Enter delivery address"
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                ></textarea>
              </div>
              <div>
                {/* <label>Delivery GST Number</label> */}
                <input
                  type="text"
                  name="delivery_gst_no"
                  value={newCustomer.delivery_gst_no}
                  onChange={handleNewCustomerChange}
                  placeholder="Enter delivery GST number"
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                />
              </div>
              <div style={{ textAlign: "center", marginTop: "5px" }}>
                <button
                  type="button"
                  onClick={addNewCustomer}
                  style={{
                    backgroundColor: "#007bff",
                    color: "#fff",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "8px",
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                >
                  Create
                </button>
                <button
                  type="button"
                  onClick={closeModal}
                  style={{
                    backgroundColor: "#ccc",
                    color: "#000",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Additional Styling */}
      <style jsx>{`
        .product-dropdown {
          list-style: none;
          padding: 0;
          margin: 0;
          border: 1px solid #ccc;
          max-height: 100px;
          overflow-y: auto;
          position: absolute;
          background-color: white;
          z-index: 10;
          width: calc(22% - 20px);
        }

        .dropdown-item {
          padding: 8px 12px;
          cursor: pointer;
        }

        .dropdown-item:hover {
          background-color: #f1f1f1;
        }

        .bill-table {
          margin-top: 20px;
        }
      `}</style>
    </div>
  );
};

export default Quotation;
