// import { Table, Button, Modal, ListGroup, Form } from "react-bootstrap";
// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { FaChevronDown } from "react-icons/fa";
// const Bill = () => {
//   const [invoiceDetails, setInvoiceDetails] = useState({
//     items: [],
//     discountRate: 0, // default discount is 0
//   });

//   const [productList, setProductList] = useState([]);
//   const [filteredProductList, setFilteredProductList] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [outletList, setOutletList] = useState([]);
//   const [selectedOutlet, setSelectedOutlet] = useState(null);
//   const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

//   const [paymentMethod, setPaymentMethod] = useState("Cash");
//   const [paidAmount, setPaidAmount] = useState(0);
//   const [returnChange, setReturnChange] = useState(0);
//   const [paymentMethods, setPaymentMethods] = useState([]);

//   const [settinglist, setSettinglist] = useState([]);
//   const [selectedAccount, setSelectedAccount] = useState("");
//   const [customerList, setCustomerList] = useState([]);
//   const [customerSearchTerm, setCustomerSearchTerm] = useState("");
//   const [filteredCustomerList, setFilteredCustomerList] = useState([]);
//   const [selectedCustomer, setSelectedCustomer] = useState(null);
//   const [showDropdown, setShowDropdown] = useState(true);
//   const [showModal, setShowModal] = useState(false);
//   const [descriptions, setDescriptions] = useState({});
//   const [visibleInputs, setVisibleInputs] = useState({});
//   const [discounts, setDiscounts] = useState({}); // Track discounts for each item
//   const [discountType, setDiscountType] = useState({});
//   const [newCustomer, setNewCustomer] = useState({
//     name: "",
//     email: "",
//     mobile: "",
//     address: "",
//     customer_gst_no: "",
//     delivery_name: "",
//     delivery_address: "",
//     delivery_gst_no: "",
//   });

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     const config = {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     };

//     // Fetch product list
//     axios
//       .get("https://luckytraders.bwsoft.in/api/productlist/", config)
//       .then((response) => {
//         if (Array.isArray(response.data)) {
//           setProductList(response.data);
//           setFilteredProductList(response.data);
//         }
//       })
//       .catch((error) => console.error("Error fetching products:", error));

//     // Fetch outlet list
//     axios
//       .get("https://luckytraders.bwsoft.in/api/outletlist/", config)
//       .then((response) => {
//         if (Array.isArray(response.data)) {
//           setOutletList(response.data);
//         }
//       })
//       .catch((error) => console.error("Error fetching outlets:", error));

//     axios
//       .get("https://luckytraders.bwsoft.in/api/paymentlist/", config)
//       .then((response) => {
//         if (Array.isArray(response.data)) {
//           setPaymentMethods(response.data.filter((method) => method.status));
//           setPaymentMethod(response.data[0]?.name || "Cash"); // Set default payment method
//         }
//       })
//       .catch((error) =>
//         console.error("Error fetching payment methods:", error)
//       );

//     // axios
//     //   .get("https://luckytraders.bwsoft.in/api/customerlist/", config)
//     //   .then((response) => {
//     //     if (Array.isArray(response.data)) {
//     //       setCustomerList(response.data);
//     //     }
//     //   })
//     //   .catch((error) => console.error("Error fetching customers:", error));

//     axios
//       .get("https://luckytraders.bwsoft.in/api/customerlist/", config)
//       .then((response) => {
//         if (Array.isArray(response.data)) {
//           setCustomerList(response.data);
//         }
//       });
//     // Open the modal when the component mounts
//     setIsModalOpen(true);
//   }, []);

//   const handleproductDiscountChange = (itemId, type, value) => {
//     setDiscounts((prev) => ({
//       ...prev,
//       [itemId]: { type, value },
//     }));
//   };

//   const toggleDiscountType = (itemId) => {
//     setDiscountType((prev) => ({
//       ...prev,
//       [itemId]: prev[itemId] === "percentage" ? "price" : "percentage",
//     }));
//   };

//   const handleSearchCustomer = (event) => {
//     const searchValue = event.target.value.toLowerCase();
//     setCustomerSearchTerm(searchValue);

//     if (searchValue === "") {
//       setFilteredCustomerList([]);
//       return;
//     }

//     const filteredCustomers = customerList.filter(
//       (customer) =>
//         customer.name.toLowerCase().includes(searchValue) ||
//         customer.mobile.includes(searchValue)
//     );

//     setFilteredCustomerList(filteredCustomers);
//   };

//   const handleSelectCustomer = (customer) => {
//     setSelectedCustomer(customer.id); // Set selected customer's ID
//     setCustomerSearchTerm(`${customer.name} - ${customer.mobile}`);
//     setFilteredCustomerList([]); // Reset dropdown
//     setShowDropdown(false);
//   };

//   useEffect(() => {
//     const fetchSettingList = async () => {
//       const token = localStorage.getItem("token");

//       try {
//         const response = await fetch(
//           "https://luckytraders.bwsoft.in/api/settinglist/",
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (!response.ok) throw new Error("Failed to fetch settings");

//         const data = await response.json();
//         console.log(data); // Log to check the structure
//         setSettinglist(Array.isArray(data) ? data : []); // Ensure it's an array
//       } catch (error) {
//         console.error("Error fetching setting list:", error);
//       }
//     };

//     fetchSettingList();
//   }, []);

//   const handleSearchChange = (event) => {
//     const searchValue = event.target.value;
//     setSearchTerm(searchValue);
//     const filteredProducts = productList.filter(
//       (product) =>
//         product.name.toLowerCase().includes(searchValue.toLowerCase()) ||
//         product.code.toLowerCase().includes(searchValue.toLowerCase())
//     );
//     setFilteredProductList(filteredProducts);
//   };

//   const handleSelectProduct = (product) => {
//     const newItem = {
//       id: product.id,
//       name: product.name,
//       gst: product.gst,
//       igst: product.igst,
//       code: product.code || "N/A",
//       price: parseFloat(product.retail_price) || 0,
//       quantity: 1,
//     };

//     setInvoiceDetails((prevDetails) => ({
//       ...prevDetails,
//       items: [...prevDetails.items, newItem],
//     }));

//     setSearchTerm("");
//     setFilteredProductList(productList);
//   };

//   const handleQuantityChange = (itemId, amount) => {
//     setInvoiceDetails((prevDetails) => ({
//       ...prevDetails,
//       items: prevDetails.items.map((item) =>
//         item.id === itemId
//           ? { ...item, quantity: Math.max(1, amount) } // Ensure valid quantity
//           : item
//       ),
//     }));
//   };

//   const updateQuantity = (itemId, value) => {
//     setInvoiceDetails((prevDetails) => ({
//       ...prevDetails,
//       items: prevDetails.items.map((item) =>
//         item.id === itemId ? { ...item, quantity: value } : item
//       ),
//     }));
//   };

//   const handleDiscountChange = (e) => {
//     const newDiscountRate = parseFloat(e.target.value) || 0;
//     setInvoiceDetails((prevDetails) => ({
//       ...prevDetails,
//       discountRate: newDiscountRate,
//     }));
//   };
//   const calculateTotalDiscount = () => {
//     return invoiceDetails.items.reduce((total, item) => {
//       const discount = discounts[item.id];

//       if (!discount) return total; // Skip items without a discount

//       let itemDiscount = 0;
//       if (discount.type === "percentage") {
//         // Percentage discount
//         itemDiscount = item.price * item.quantity * (discount.value / 100);
//       } else if (discount.type === "price") {
//         // Price discount
//         itemDiscount = discount.value * item.quantity;
//       }

//       return total + itemDiscount;
//     }, 0);
//   };

//   // Calculate the discount amount
//   const totalDiscount = calculateTotalDiscount();

//   const subtotal = invoiceDetails.items.reduce(
//     (acc, item) => acc + Number(item.price || 0) * Number(item.quantity || 0),
//     0
//   );

//   const totalGst = invoiceDetails.items.reduce(
//     (acc, item) => acc + ((item.gst * item.price * item.quantity) / 100 || 0),
//     0
//   );
//   const totalIgst = invoiceDetails.items.reduce(
//     (acc, item) => acc + ((item.igst * item.price * item.quantity) / 100 || 0),
//     0
//   );

//   const discountAmount = (subtotal * invoiceDetails.discountRate) / 100;
//   // const totalPayable = subtotal + totalIgst + totalGst - discountAmount;
//   const totalPayable = subtotal + totalGst + totalIgst - totalDiscount;

//   const handleOpenModal = () => setIsModalOpen(true);
//   const handleCloseModal = () => setIsModalOpen(false);

//   const handleSelectOutlet = (outlet) => {
//     setSelectedOutlet(outlet);
//     localStorage.setItem("selectedOutletId", outlet.id);
//     handleCloseModal();
//   };
//   const handleDescriptionChange = (productId, value) => {
//     setDescriptions((prev) => ({
//       ...prev,
//       [productId]: value,
//     }));
//   };

//   const toggleInputVisibility = (productId) => {
//     setVisibleInputs((prev) => ({
//       ...prev,
//       [productId]: !prev[productId],
//     }));
//   };
//   const handleOpenPaymentModal = () => {
//     setIsPaymentModalOpen(true);
//   };

//   const navigate = useNavigate();

//   const handleMakePayment = (
//     selectedOutlet,
//     selectedCustomer,
//     paymentMethod,
//     totalPayable,
//     paidAmount,
//     invoiceDetails,
//     selectedAccount
//   ) => {
//     console.log("Payment Details Received:");
//     console.log("Selected Outlet:", selectedOutlet);
//     console.log("Selected Customer:", selectedCustomer);
//     console.log("Payment Method:", paymentMethod);
//     console.log("Total Payable:", totalPayable);
//     console.log("Paid Amount:", paidAmount);
//     console.log("Invoice Details:", invoiceDetails);
//     console.log("Selected Account:", selectedAccount);
//     // Ensure you have the correct selected values
//     if (!selectedOutlet || !selectedCustomer || !paymentMethod) {
//       alert("Please select an outlet, customer, and payment method.");
//       return;
//     }
//     const gst_charge = totalGst.toFixed(2);
//     const igst_charge = totalIgst.toFixed(2);

//     const orderData = {
//       card_number: "",
//       cgst_charge: "0.00",
//       cgst_percent: "0.00",
//       cheque_number: "",
//       created_by: 1,
//       customer_id: selectedCustomer,

//       discount_percentage: "0.00",
//       discount_total: discountAmount.toFixed(2),
//       grand_total: totalPayable.toFixed(2),
//       igst_charge: igst_charge,
//       gst_charge: gst_charge,

//       order_items: invoiceDetails.items.map((item) => ({
//         igst_percent: "0.00",
//         gst_percent: "0.00",
//         product_id: item.id,
//         product_name: item.name,
//         product_code: item.code,
//         qty: item.quantity,
//         category_id: item.categoryId || 0,
//         purchase_price: item.purchasePrice || "0.00",
//         retail_price: item.price || "0.00",
//         discount_percentage:
//           discounts[item.id]?.type === "percentage"
//             ? discounts[item.id]?.value || "0.00"
//             : "0.00",
//         discount_amount:
//           discounts[item.id]?.type === "price"
//             ? discounts[item.id]?.value || "0.00"
//             : "0.00",

//         description: descriptions[item.id] || "",
//       })),
//       outlet_id: selectedOutlet.id,
//       paid_amount: paidAmount,
//       payment_id: parseInt(paymentMethod, 10),
//       refund_status: 0,
//       remark: "",
//       return_change: (paidAmount - totalPayable).toFixed(2),
//       sgst_charge: "0.00",
//       sgst_percent: "0.00",
//       status: 1,
//       subtotal: subtotal.toFixed(2),
//       total_items: invoiceDetails.items.length,
//       updated_by: null,
//       vt_status: 1,
//       selected_account: selectedAccount,
//     };

//     // Get the token from localStorage
//     const token = localStorage.getItem("token");

//     // Set up the request config with headers
//     const config = {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     };

//     axios
//       .post(
//         "https://luckytraders.bwsoft.in/api/create_order/",
//         orderData,
//         config
//       )
//       .then((response) => {
//         console.log("Order created successfully:", response.data);
//         console.log("Selected Account:", selectedAccount);
//         navigate("/Createbill", {
//           state: { orderDetails: response.data, selectedAccount },
//         });
//       })
//       .catch((error) => {
//         console.error("Error creating order:", error.response.data);
//       });
//   };
//   const handleRemoveItem = (id) => {
//     setInvoiceDetails((prevDetails) => ({
//       ...prevDetails,
//       items: prevDetails.items.filter((item) => item.id !== id),
//     }));
//   };

//   const openModal = () => setShowModal(true);
//   const closeModal = () => setShowModal(false);

//   const handleNewCustomerChange = (e) => {
//     const { name, value } = e.target;
//     setNewCustomer((prev) => ({ ...prev, [name]: value }));
//   };

//   const addNewCustomer = async () => {
//     const token = localStorage.getItem("token");
//     try {
//       const response = await axios.post(
//         "https://luckytraders.bwsoft.in/api/customer/create/",
//         newCustomer,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       setCustomerList((prev) => [...prev, response.data.customer_data]);
//       setCustomerSearchTerm("");
//       setFilteredCustomerList([]);
//       closeModal();
//     } catch (error) {
//       console.error("Error adding customer:", error);
//     }
//   };
//   const handleSameAsBilling = (e) => {
//     if (e.target.checked) {
//       setNewCustomer((prev) => ({
//         ...prev,
//         delivery_name: prev.name,
//         delivery_address: prev.address,
//         delivery_gst_no: prev.customer_gst_no,
//       }));
//     } else {
//       setNewCustomer((prev) => ({
//         ...prev,
//         delivery_name: "",
//         delivery_address: "",
//         delivery_gst_no: "",
//       }));
//     }
//   };
//   const handlePriceChange = (itemId, newPrice) => {
//     // Update the price in the state
//     setInvoiceDetails((prev) => {
//       return {
//         ...prev,
//         items: prev.items.map((item) =>
//           item.id === itemId ? { ...item, price: newPrice } : item
//         ),
//       };
//     });
//   };

//   return (
//     <div className="bill-template" id="tabelcontent">
//       <h1 className="text-center">Invoice</h1>
//       <div className="bill-header">
//         <div className="product-search">
//           <div
//             style={{ position: "relative", width: "100%", maxWidth: "400px" }}
//           >
//             <input
//               type="text"
//               id="product-search"
//               value={searchTerm}
//               onChange={handleSearchChange}
//               placeholder="Type product name or code..."
//               style={{
//                 width: "100%",
//                 padding: "10px 40px 10px 15px", // Padding for space around the icon
//                 border: "2px solid #ddd",
//                 borderRadius: "8px",
//                 fontSize: "16px",
//               }}
//             />
//             <span
//               style={{
//                 position: "absolute",
//                 right: "15px",
//                 top: "50%",
//                 transform: "translateY(-50%)",
//                 cursor: "pointer",
//                 color: "#999",
//               }}
//             >
//               🔍{" "}
//             </span>
//           </div>

//           {searchTerm && filteredProductList.length > 0 && (
//             <ul className="product-dropdown">
//               {filteredProductList.map((product) => (
//                 <li
//                   key={product.id}
//                   onClick={() => handleSelectProduct(product)}
//                   className="dropdown-item"
//                 >
//                   {product.name} - ₹{product.retail_price || 0}
//                 </li>
//               ))}
//             </ul>
//           )}
//         </div>
//         <div className="customer-search">
//           <div
//             style={{ position: "relative", width: "100%", maxWidth: "400px" }}
//           >
//             <input
//               type="text"
//               value={customerSearchTerm}
//               onChange={handleSearchCustomer}
//               placeholder="customer name or mobile..."
//               style={{
//                 width: "100%",
//                 padding: "10px 40px 10px 15px",
//                 border: "2px solid #ddd",
//                 borderRadius: "8px",
//                 fontSize: "16px",
//               }}
//             />
//             <span
//               style={{
//                 position: "absolute",
//                 right: "15px",
//                 top: "50%",
//                 transform: "translateY(-50%)",
//                 cursor: "pointer",
//                 color: "#999",
//               }}
//             ></span>
//           </div>

//           {showDropdown &&
//             customerSearchTerm &&
//             filteredCustomerList.length > 0 && (
//               <ul className="product-dropdown">
//                 {filteredCustomerList.map((customer) => (
//                   <li
//                     key={customer.id}
//                     onClick={() => handleSelectCustomer(customer)}
//                     className="dropdown-item"
//                     style={{
//                       padding: "10px",
//                       cursor: "pointer",
//                       borderBottom: "1px solid #ddd",
//                     }}
//                   >
//                     {customer.name} - {customer.mobile}
//                   </li>
//                 ))}
//               </ul>
//             )}

//           {showDropdown &&
//             customerSearchTerm &&
//             filteredCustomerList.length === 0 && (
//               <ul className="product-dropdown">
//                 <li
//                   onClick={openModal}
//                   style={{
//                     padding: "10px",
//                     cursor: "pointer",
//                     borderBottom: "1px solid #ddd",
//                     color: "#007bff",
//                     backgroundColor: "#fff",
//                   }}
//                 >
//                   Add New Customer
//                 </li>
//               </ul>
//             )}
//         </div>
//         <div>
//           {" "}
//           <p>
//             Outlet:{" "}
//             <span style={{ fontSize: "25px" }}>
//               {selectedOutlet ? selectedOutlet.name : "No outlet selected"}
//             </span>
//           </p>
//         </div>
//         <div>
//           {" "}
//           <Button onClick={handleOpenModal}>Change Outlet</Button>
//         </div>
//       </div>
//       <Table striped bordered hover className="bill-table">
//         <thead>
//           <tr>
//             <th>#</th>
//             <th>Product Name</th>
//             <th>HSN Code</th>
//             <th>Quantity</th>
//             <th>Price</th>
//             <th>Gst</th>
//             <th>IGst</th>
//             <th>Discount</th>
//             <th>Total</th>
//             <th>Remove</th>
//           </tr>
//         </thead>
//         <tbody>
//           {invoiceDetails.items.map((item, index) => (
//             <tr key={item.id}>
//               <td>{index + 1}</td>

//               <td>
//                 <div
//                   style={{ cursor: "pointer", color: "#007bff" }}
//                   onClick={() => toggleInputVisibility(item.id)}
//                 >
//                   {item.name}
//                 </div>
//                 {visibleInputs[item.id] && (
//                   <textarea
//                     value={descriptions[item.id] || ""}
//                     onChange={(e) =>
//                       handleDescriptionChange(item.id, e.target.value)
//                     }
//                     placeholder="Enter description"
//                     style={{
//                       width: "100%",
//                       padding: "5px",
//                       border: "1px solid #ddd",
//                       borderRadius: "4px",
//                       resize: "none",
//                       marginTop: "5px",
//                     }}
//                   />
//                 )}
//                 {item.quantity <= 0 && (
//                   <div style={{ color: "red", fontSize: "0.9em" }}>
//                     Error: No quantity available
//                   </div>
//                 )}
//               </td>
//               <td>{item.code}</td>
//               <td>
//                 <div className="quantity-control">
//                   <button
//                     className="quantity-button"
//                     onClick={() =>
//                       handleQuantityChange(item.id, item.quantity - 1)
//                     }
//                   >
//                     -
//                   </button>

//                   <input
//                     type="number"
//                     value={item.quantity}
//                     onChange={(e) => {
//                       const newQuantity =
//                         e.target.value === ""
//                           ? ""
//                           : parseInt(e.target.value, 10);
//                       if (
//                         newQuantity === "" ||
//                         (!isNaN(newQuantity) && newQuantity >= 0)
//                       ) {
//                         updateQuantity(item.id, newQuantity); // Handles updating the temp value
//                       }
//                     }}
//                     onBlur={(e) => {
//                       // Restore a valid quantity on blur if input is empty
//                       if (
//                         e.target.value === "" ||
//                         parseInt(e.target.value, 10) < 1
//                       ) {
//                         handleQuantityChange(item.id, 1);
//                       }
//                     }}
//                     min="0"
//                     className="quantity-input"
//                   />

//                   <button
//                     className="quantity-button"
//                     onClick={() =>
//                       handleQuantityChange(item.id, item.quantity + 1)
//                     }
//                   >
//                     +
//                   </button>
//                 </div>
//               </td>

//               {/* <td>₹{item.price.toFixed(2)}</td> */}
//               <td>
//                 <input
//                   type="number"
//                   value={item.price}
//                   onChange={(e) =>
//                     handlePriceChange(item.id, parseFloat(e.target.value))
//                   }
//                   onBlur={(e) => {
//                     // Optionally validate price or apply default if invalid
//                     if (
//                       isNaN(parseFloat(e.target.value)) ||
//                       parseFloat(e.target.value) < 0
//                     ) {
//                       handlePriceChange(item.id, 0); // Default to 0 if invalid
//                     }
//                   }}
//                   style={{
//                     width: "80%",
//                     padding: "5px",
//                     border: "1px solid #ddd",
//                     borderRadius: "4px",
//                   }}
//                 />
//               </td>

//               <td>{item.gst ? `${item.gst}%` : "0%"}</td>
//               <td>{item.igst ? `${item.igst}%` : "0%"}</td>
//               <td>
//                 <div style={{ position: "relative" }}>
//                   <input
//                     type="text"
//                     value={
//                       discounts[item.id]?.value || "" // Discount value
//                     }
//                     onChange={(e) =>
//                       handleproductDiscountChange(
//                         item.id,
//                         discountType[item.id] || "percentage",
//                         e.target.value
//                       )
//                     }
//                     placeholder={
//                       discountType[item.id] === "price"
//                         ? "Enter discount price"
//                         : "Enter discount %"
//                     }
//                     style={{
//                       width: "80%",
//                       padding: "5px",
//                       marginRight: "5px",
//                     }}
//                   />
//                   <button
//                     onClick={() => toggleDiscountType(item.id)}
//                     style={{
//                       background: "none",
//                       border: "none",
//                       cursor: "pointer",
//                       color: "#007bff",
//                     }}
//                   >
//                     <FaChevronDown />
//                   </button>
//                 </div>
//                 <small>
//                   Using{" "}
//                   {discountType[item.id] === "price" ? "Price" : "Percentage"}
//                 </small>
//               </td>
// <td>
//   ₹
//   {(
//     item.price * item.quantity -
//     (discountType[item.id] === "percentage"
//       ? (item.price *
//           item.quantity *
//           (discounts[item.id]?.value || 0)) /
//         100
//       : discounts[item.id]?.value || 0) +
//     (item.price * item.quantity * (item.gst || 0)) / 100 +
//     (item.price * item.quantity * (item.igst || 0)) / 100
//   ).toFixed(2)}
// </td>
//               {/* <td>
//                 ₹
//                 {(
//                   item.price * item.quantity +
//                   (item.price * item.quantity * (item.gst || 0)) / 100 +
//                   (item.price * item.quantity * (item.igst || 0)) / 100
//                 ).toFixed(2)}
//               </td> */}
//               <td>
//                 <button
//                   className="btn btn-danger"
//                   onClick={() => handleRemoveItem(item.id)}
//                 >
//                   X
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       <Table striped bordered hover className="custom-bill-summary-table">
//         <thead>
//           <tr>
//             <th colSpan={4} className="table-title">
//               Bill Summary
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="label">Total Purchase Quantity</td>

//             <td>
//               {invoiceDetails.items.reduce(
//                 (acc, item) => acc + item.quantity,
//                 0
//               )}
//             </td>
//             <td className="label">Subtotal</td>
//             <td>₹{subtotal.toFixed(2)}</td>
//           </tr>
//           <tr>
//             <td className="label">Discount Rate (%):</td>
//             <td>
//               <input
//                 type="text"
//                 id="discount"
//                 value={invoiceDetails.discountRate}
//                 onChange={handleDiscountChange}
//                 min="0"
//                 max="100"
//                 step="0.1"
//               />
//             </td>

//             <td className="label">GST Total</td>
//             <td>₹{totalGst.toFixed(2)}</td>
//           </tr>
//           {/* <tr>
//             <td className="label">Discount ({invoiceDetails.discountRate}%)</td>
//             <td>-₹{discountAmount.toFixed(2)}</td>
//             <td className="label">IGST Total</td>
//             <td>₹{totalIgst.toFixed(2)}</td>
//           </tr> */}
//           <tr>
//             <td className="label">Discount</td>
//             <td>-₹{totalDiscount.toFixed(2)}</td>
//             <td className="label">IGST Total</td>
//             <td>₹{totalIgst.toFixed(2)}</td>
//           </tr>

//           <tr>
//             <td colSpan={2} className="total-label">
//               <strong>Total Payable</strong>
//             </td>
//             <td colSpan={2} className="total-value">
//               <strong>₹{totalPayable.toFixed(2)}</strong>
//             </td>
//           </tr>
//         </tbody>
//       </Table>

//       <div className="bill-footer d-flex g-2 justify-content-evenly">
//         <Button variant="danger">Cancel</Button>
//         <Button variant="danger"> Hold Bill</Button>
//         <Button variant="primary" onClick={handleOpenPaymentModal}>
//           Make Payment
//         </Button>
//       </div>
//       <p>Thank you for your business!</p>

//       <Modal
//         show={isPaymentModalOpen}
//         onHide={() => setIsPaymentModalOpen(false)}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Make Payment</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {/* <Form.Group controlId="customerName">
//             <Form.Label>Select Customer</Form.Label>
//             <Form.Control
//               as="select"
//               value={selectedCustomer}
//               onChange={(e) => setSelectedCustomer(e.target.value)}
//             >
//               <option value="">Select Customer</option>
//               {customerList.map((customer) => (
//                 <option key={customer.id} value={customer.id}>
//                   {customer.name}
//                 </option>
//               ))}
//             </Form.Control>
//           </Form.Group> */}

//           <Form.Group controlId="accountSelect">
//             <Form.Label>Select Account</Form.Label>
//             <Form.Control
//               as="select"
//               value={selectedAccount}
//               onChange={(e) => setSelectedAccount(e.target.value)}
//             >
//               <option value="">Select account</option>
//               {Array.isArray(settinglist) && settinglist.length > 0 ? (
//                 settinglist.map((account) => (
//                   <option key={account.id} value={account.id}>
//                     {account.name} {/* Ensure you show the account name */}
//                   </option>
//                 ))
//               ) : (
//                 <option disabled>No accounts available</option>
//               )}
//             </Form.Control>
//           </Form.Group>

//           <Form.Group controlId="paymentMethod">
//             <Form.Label>Select Payment Method</Form.Label>
//             <Form.Control
//               as="select"
//               value={paymentMethod}
//               onChange={(e) => setPaymentMethod(Number(e.target.value))} // Convert to number
//             >
//               <option value="">Select Payment Method</option>
//               {paymentMethods.map((method) => (
//                 <option key={method.id} value={method.id}>
//                   {method.name}
//                 </option>
//               ))}
//             </Form.Control>
//           </Form.Group>

//           <Form.Group controlId="paidAmount">
//             <Form.Label>Paid Amount</Form.Label>
//             <Form.Control
//               type="number"
//               value={paidAmount}
//               onChange={(e) => setPaidAmount(Number(e.target.value))}
//             />
//           </Form.Group>

//           <Form.Group controlId="returnChange">
//             <Form.Label>Return Change</Form.Label>
//             <Form.Control
//               type="text"
//               value={`₹${(paidAmount - totalPayable).toFixed(2)}`}
//               readOnly
//             />
//           </Form.Group>

//           <p>Total Payable Amount: ₹{totalPayable.toFixed(2)}</p>
//           <p>Total Purchased Items: {invoiceDetails.items.length}</p>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button
//             variant="primary"
//             onClick={() =>
//               handleMakePayment(
//                 selectedOutlet,
//                 selectedCustomer,
//                 paymentMethod,
//                 totalPayable,
//                 paidAmount,
//                 invoiceDetails,
//                 selectedAccount
//               )
//             }
//           >
//             Make Payment
//           </Button>
//           <Button
//             variant="secondary"
//             onClick={() => setIsPaymentModalOpen(false)}
//           >
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       <Modal show={isModalOpen} onHide={handleCloseModal} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Select Outlet</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <ListGroup>
//             {outletList.map((outlet) => (
//               <ListGroup.Item
//                 key={outlet.id}
//                 action
//                 onClick={() => handleSelectOutlet(outlet)}
//                 className="d-flex flex-column justify-content-center align-items-center"
//               >
//                 <strong>{outlet.name}</strong>
//                 <div>{outlet.address}</div>
//                 <div>Mobile:{outlet.mobile}</div>
//               </ListGroup.Item>
//             ))}
//           </ListGroup>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       {showModal && (
//         <div
//           style={{
//             position: "fixed",
//             top: 0,
//             left: 0,
//             width: "100%",
//             height: "100%",
//             backgroundColor: "rgba(0, 0, 0, 0.5)",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             zIndex: 1000,
//           }}
//         >
//           <div
//             style={{
//               backgroundColor: "#fff",
//               borderRadius: "12px",
//               padding: "15px",
//               width: "450px",
//               boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
//             }}
//           >
//             <h2 style={{ textAlign: "center", marginBottom: "5px" }}>
//               Create Customer
//             </h2>
//             <form>
//               <div>
//                 {/* <label>Name *</label> */}
//                 <input
//                   type="text"
//                   name="name"
//                   value={newCustomer.name}
//                   onChange={handleNewCustomerChange}
//                   placeholder="Enter name"
//                   required
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     marginTop: "5px",
//                     border: "1px solid #ddd",
//                     borderRadius: "8px",
//                   }}
//                 />
//               </div>
//               <div>
//                 {/* <label>Email Address</label> */}
//                 <input
//                   type="email"
//                   name="email"
//                   value={newCustomer.email}
//                   onChange={handleNewCustomerChange}
//                   placeholder="Enter email address"
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     marginTop: "5px",
//                     border: "1px solid #ddd",
//                     borderRadius: "8px",
//                   }}
//                 />
//               </div>
//               <div>
//                 {/* <label>Mobile Number *</label> */}
//                 <input
//                   type="text"
//                   name="mobile"
//                   value={newCustomer.mobile}
//                   onChange={handleNewCustomerChange}
//                   placeholder="Enter mobile number"
//                   required
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     marginTop: "5px",
//                     border: "1px solid #ddd",
//                     borderRadius: "8px",
//                   }}
//                 />
//               </div>
//               <div>
//                 {/* <label>Address</label> */}
//                 <textarea
//                   name="address"
//                   value={newCustomer.address}
//                   onChange={handleNewCustomerChange}
//                   placeholder="Enter address"
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     marginTop: "5px",
//                     border: "1px solid #ddd",
//                     borderRadius: "8px",
//                   }}
//                 ></textarea>
//               </div>
//               <div>
//                 {/* <label>GST Number</label> */}
//                 <input
//                   type="text"
//                   name="customer_gst_no"
//                   value={newCustomer.customer_gst_no}
//                   onChange={handleNewCustomerChange}
//                   placeholder="Enter GST number"
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     marginTop: "5px",
//                     border: "1px solid #ddd",
//                     borderRadius: "8px",
//                   }}
//                 />
//               </div>
//               <h4 style={{ marginBottom: "5px" }}>Customer Delivery Address</h4>
//               <div>
//                 <input
//                   type="checkbox"
//                   id="sameAsBilling"
//                   onChange={handleSameAsBilling}
//                 />
//                 <label htmlFor="sameAsBilling">
//                   Same as billing name & address
//                 </label>
//               </div>
//               <div>
//                 {/* <label>Delivery Name</label> */}
//                 <input
//                   type="text"
//                   name="delivery_name"
//                   value={newCustomer.delivery_name}
//                   onChange={handleNewCustomerChange}
//                   placeholder="Enter delivery name"
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     marginTop: "5px",
//                     border: "1px solid #ddd",
//                     borderRadius: "8px",
//                   }}
//                 />
//               </div>
//               <div>
//                 {/* <label>Delivery Address</label> */}
//                 <textarea
//                   name="delivery_address"
//                   value={newCustomer.delivery_address}
//                   onChange={handleNewCustomerChange}
//                   placeholder="Enter delivery address"
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     marginTop: "5px",
//                     border: "1px solid #ddd",
//                     borderRadius: "8px",
//                   }}
//                 ></textarea>
//               </div>
//               <div>
//                 {/* <label>Delivery GST Number</label> */}
//                 <input
//                   type="text"
//                   name="delivery_gst_no"
//                   value={newCustomer.delivery_gst_no}
//                   onChange={handleNewCustomerChange}
//                   placeholder="Enter delivery GST number"
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     marginTop: "5px",
//                     border: "1px solid #ddd",
//                     borderRadius: "8px",
//                   }}
//                 />
//               </div>
//               <div style={{ textAlign: "center", marginTop: "5px" }}>
//                 <button
//                   type="button"
//                   onClick={addNewCustomer}
//                   style={{
//                     backgroundColor: "#007bff",
//                     color: "#fff",
//                     padding: "10px 20px",
//                     border: "none",
//                     borderRadius: "8px",
//                     cursor: "pointer",
//                     marginRight: "10px",
//                   }}
//                 >
//                   Create
//                 </button>
//                 <button
//                   type="button"
//                   onClick={closeModal}
//                   style={{
//                     backgroundColor: "#ccc",
//                     color: "#000",
//                     padding: "10px 20px",
//                     border: "none",
//                     borderRadius: "8px",
//                     cursor: "pointer",
//                   }}
//                 >
//                   Cancel
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       )}

//       <style jsx>{`
//         .product-dropdown {
//           list-style: none;
//           padding: 0;
//           margin: 0;
//           border: 1px solid #ccc;
//           max-height: 100px;
//           overflow-y: auto;
//           position: absolute;
//           background-color: white;
//           z-index: 10;
//           width: calc(30% - 20px);
//         }

//         .dropdown-item {
//           padding: 8px 12px;
//           cursor: pointer;
//         }

//         .dropdown-item:hover {
//           background-color: #f1f1f1;
//         }

//         .bill-table {
//           margin-top: 20px;
//         }
//       `}</style>
//     </div>
//   );
// };

// export default Bill;

// import { Table, Button, Modal, ListGroup, Form } from "react-bootstrap";
// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { FaChevronDown } from "react-icons/fa";
// const Bill = () => {
//   const [invoiceDetails, setInvoiceDetails] = useState({
//     items: [],
//     discountRate: 0, // default discount is 0
//   });

//   const [productList, setProductList] = useState([]);
//   const [filteredProductList, setFilteredProductList] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [outletList, setOutletList] = useState([]);
//   const [selectedOutlet, setSelectedOutlet] = useState(null);
//   const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

//   const [paymentMethod, setPaymentMethod] = useState("Cash");
//   const [paidAmount, setPaidAmount] = useState(0);
//   const [returnChange, setReturnChange] = useState(0);
//   const [paymentMethods, setPaymentMethods] = useState([]);

//   const [settinglist, setSettinglist] = useState([]);
//   const [selectedAccount, setSelectedAccount] = useState("");
//   const [customerList, setCustomerList] = useState([]);
//   const [customerSearchTerm, setCustomerSearchTerm] = useState("");
//   const [filteredCustomerList, setFilteredCustomerList] = useState([]);
//   const [selectedCustomer, setSelectedCustomer] = useState(null);
//   const [showDropdown, setShowDropdown] = useState(true);
//   const [showModal, setShowModal] = useState(false);
//   const [descriptions, setDescriptions] = useState({});
//   const [visibleInputs, setVisibleInputs] = useState({});
//   const [discounts, setDiscounts] = useState({}); // Track discounts for each item
//   const [discountType, setDiscountType] = useState({});
//   const [newCustomer, setNewCustomer] = useState({
//     name: "",
//     email: "",
//     mobile: "",
//     address: "",
//     customer_gst_no: "",
//     delivery_name: "",
//     delivery_address: "",
//     delivery_gst_no: "",
//   });

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     const config = {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     };

//     // Fetch product list
//     axios
//       .get("https://luckytraders.bwsoft.in/api/productlist/", config)
//       .then((response) => {
//         if (Array.isArray(response.data)) {
//           setProductList(response.data);
//           setFilteredProductList(response.data);
//         }
//       })
//       .catch((error) => console.error("Error fetching products:", error));

//     // Fetch outlet list
//     axios
//       .get("https://luckytraders.bwsoft.in/api/outletlist/", config)
//       .then((response) => {
//         if (Array.isArray(response.data)) {
//           setOutletList(response.data);
//         }
//       })
//       .catch((error) => console.error("Error fetching outlets:", error));

//     axios
//       .get("https://luckytraders.bwsoft.in/api/paymentlist/", config)
//       .then((response) => {
//         if (Array.isArray(response.data)) {
//           setPaymentMethods(response.data.filter((method) => method.status));
//           setPaymentMethod(response.data[0]?.name || "Cash"); // Set default payment method
//         }
//       })
//       .catch((error) =>
//         console.error("Error fetching payment methods:", error)
//       );

//     // axios
//     //   .get("https://luckytraders.bwsoft.in/api/customerlist/", config)
//     //   .then((response) => {
//     //     if (Array.isArray(response.data)) {
//     //       setCustomerList(response.data);
//     //     }
//     //   })
//     //   .catch((error) => console.error("Error fetching customers:", error));

//     axios
//       .get("https://luckytraders.bwsoft.in/api/customerlist/", config)
//       .then((response) => {
//         if (Array.isArray(response.data)) {
//           setCustomerList(response.data);
//         }
//       });
//     // Open the modal when the component mounts
//     setIsModalOpen(true);
//   }, []);

//   const handleproductDiscountChange = (itemId, type, value) => {
//     setDiscounts((prev) => ({
//       ...prev,
//       [itemId]: { type, value },
//     }));
//   };

//   const toggleDiscountType = (itemId) => {
//     setDiscountType((prev) => ({
//       ...prev,
//       [itemId]: prev[itemId] === "percentage" ? "price" : "percentage",
//     }));
//   };

//   const handleSearchCustomer = (event) => {
//     const searchValue = event.target.value.toLowerCase();
//     setCustomerSearchTerm(searchValue);

//     if (searchValue === "") {
//       setFilteredCustomerList([]);
//       return;
//     }

//     const filteredCustomers = customerList.filter(
//       (customer) =>
//         customer.name.toLowerCase().includes(searchValue) ||
//         customer.mobile.includes(searchValue)
//     );

//     setFilteredCustomerList(filteredCustomers);
//   };

//   const handleSelectCustomer = (customer) => {
//     setSelectedCustomer(customer.id); // Set selected customer's ID
//     setCustomerSearchTerm(`${customer.name} - ${customer.mobile}`);
//     setFilteredCustomerList([]); // Reset dropdown
//     setShowDropdown(false);
//   };

//   useEffect(() => {
//     const fetchSettingList = async () => {
//       const token = localStorage.getItem("token");

//       try {
//         const response = await fetch(
//           "https://luckytraders.bwsoft.in/api/settinglist/",
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (!response.ok) throw new Error("Failed to fetch settings");

//         const data = await response.json();
//         console.log(data); // Log to check the structure
//         setSettinglist(Array.isArray(data) ? data : []); // Ensure it's an array
//       } catch (error) {
//         console.error("Error fetching setting list:", error);
//       }
//     };

//     fetchSettingList();
//   }, []);

//   const handleSearchChange = (event) => {
//     const searchValue = event.target.value;
//     setSearchTerm(searchValue);
//     const filteredProducts = productList.filter(
//       (product) =>
//         product.name.toLowerCase().includes(searchValue.toLowerCase()) ||
//         product.code.toLowerCase().includes(searchValue.toLowerCase())
//     );
//     setFilteredProductList(filteredProducts);
//   };

//   const handleSelectProduct = (product) => {
//     const newItem = {
//       id: product.id,
//       name: product.name,
//       gst: product.gst,
//       igst: product.igst,
//       code: product.code || "N/A",
//       price: parseFloat(product.retail_price) || 0,
//       quantity: 1,
//     };

//     setInvoiceDetails((prevDetails) => ({
//       ...prevDetails,
//       items: [...prevDetails.items, newItem],
//     }));

//     setSearchTerm("");
//     setFilteredProductList(productList);
//   };

//   const handleQuantityChange = (itemId, amount) => {
//     setInvoiceDetails((prevDetails) => ({
//       ...prevDetails,
//       items: prevDetails.items.map((item) =>
//         item.id === itemId
//           ? { ...item, quantity: Math.max(1, amount) } // Ensure valid quantity
//           : item
//       ),
//     }));
//   };

//   const updateQuantity = (itemId, value) => {
//     setInvoiceDetails((prevDetails) => ({
//       ...prevDetails,
//       items: prevDetails.items.map((item) =>
//         item.id === itemId ? { ...item, quantity: value } : item
//       ),
//     }));
//   };

//   const handleDiscountChange = (e) => {
//     const newDiscountRate = parseFloat(e.target.value) || 0;
//     setInvoiceDetails((prevDetails) => ({
//       ...prevDetails,
//       discountRate: newDiscountRate,
//     }));
//   };
//   const calculateTotalDiscount = () => {
//     return invoiceDetails.items.reduce((total, item) => {
//       const discount = discounts[item.id];

//       if (!discount) return total; // Skip items without a discount

//       let itemDiscount = 0;
//       if (discount.type === "percentage") {
//         // Percentage discount
//         itemDiscount = item.price * item.quantity * (discount.value / 100);
//       } else if (discount.type === "price") {
//         // Price discount
//         itemDiscount = discount.value * item.quantity;
//       }

//       return total + itemDiscount;
//     }, 0);
//   };

//   // Calculate the discount amount
//   const totalDiscount = calculateTotalDiscount();

//   const subtotal = invoiceDetails.items.reduce(
//     (acc, item) => acc + Number(item.price || 0) * Number(item.quantity || 0),
//     0
//   );

//   const totalGst = invoiceDetails.items.reduce(
//     (acc, item) => acc + ((item.gst * item.price * item.quantity) / 100 || 0),
//     0
//   );
//   const totalIgst = invoiceDetails.items.reduce(
//     (acc, item) => acc + ((item.igst * item.price * item.quantity) / 100 || 0),
//     0
//   );

//   const discountAmount = (subtotal * invoiceDetails.discountRate) / 100;
//   // const totalPayable = subtotal + totalIgst + totalGst - discountAmount;
//   const totalPayable = subtotal + totalGst + totalIgst - totalDiscount;

//   const handleOpenModal = () => setIsModalOpen(true);
//   const handleCloseModal = () => setIsModalOpen(false);

//   const handleSelectOutlet = (outlet) => {
//     setSelectedOutlet(outlet);
//     localStorage.setItem("selectedOutletId", outlet.id);
//     handleCloseModal();
//   };
//   const handleDescriptionChange = (productId, value) => {
//     setDescriptions((prev) => ({
//       ...prev,
//       [productId]: value,
//     }));
//   };

//   const toggleInputVisibility = (productId) => {
//     setVisibleInputs((prev) => ({
//       ...prev,
//       [productId]: !prev[productId],
//     }));
//   };
//   const handleOpenPaymentModal = () => {
//     setIsPaymentModalOpen(true);
//   };

//   const navigate = useNavigate();

//   const handleMakePayment = (
//     selectedOutlet,
//     selectedCustomer,
//     paymentMethod,
//     totalPayable,
//     paidAmount,
//     invoiceDetails,
//     selectedAccount
//   ) => {
//     console.log("Payment Details Received:");
//     console.log("Selected Outlet:", selectedOutlet);
//     console.log("Selected Customer:", selectedCustomer);
//     console.log("Payment Method:", paymentMethod);
//     console.log("Total Payable:", totalPayable);
//     console.log("Paid Amount:", paidAmount);
//     console.log("Invoice Details:", invoiceDetails);
//     console.log("Selected Account:", selectedAccount);
//     // Ensure you have the correct selected values
//     if (!selectedOutlet || !selectedCustomer || !paymentMethod) {
//       alert("Please select an outlet, customer, and payment method.");
//       return;
//     }
//     const gst_charge = totalGst.toFixed(2);
//     const igst_charge = totalIgst.toFixed(2);

//     const orderData = {
//       card_number: "",
//       cgst_charge: "0.00",
//       cgst_percent: "0.00",
//       cheque_number: "",
//       created_by: 1,
//       customer_id: selectedCustomer,

//       discount_percentage: "0.00",
//       discount_total: discountAmount.toFixed(2),
//       grand_total: totalPayable.toFixed(2),
//       igst_charge: igst_charge,
//       gst_charge: gst_charge,

//       order_items: invoiceDetails.items.map((item) => ({
//         igst_percent: "0.00",
//         gst_percent: "0.00",
//         product_id: item.id,
//         product_name: item.name,
//         product_code: item.code,
//         qty: item.quantity,
//         category_id: item.categoryId || 0,
//         purchase_price: item.purchasePrice || "0.00",
//         retail_price: item.price || "0.00",
//         discount_percentage:
//           discounts[item.id]?.type === "percentage"
//             ? discounts[item.id]?.value || "0.00"
//             : "0.00",
//         discount_amount:
//           discounts[item.id]?.type === "price"
//             ? discounts[item.id]?.value || "0.00"
//             : "0.00",

//         description: descriptions[item.id] || "",
//       })),
//       outlet_id: selectedOutlet.id,
//       paid_amount: paidAmount,
//       payment_id: parseInt(paymentMethod, 10),
//       refund_status: 0,
//       remark: "",
//       return_change: (paidAmount - totalPayable).toFixed(2),
//       sgst_charge: "0.00",
//       sgst_percent: "0.00",
//       status: 1,
//       subtotal: subtotal.toFixed(2),
//       total_items: invoiceDetails.items.length,
//       updated_by: null,
//       vt_status: 1,
//       selected_account: selectedAccount,
//     };

//     // Get the token from localStorage
//     const token = localStorage.getItem("token");

//     // Set up the request config with headers
//     const config = {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     };

//     axios
//       .post(
//         "https://luckytraders.bwsoft.in/api/create_order/",
//         orderData,
//         config
//       )
//       .then((response) => {
//         console.log("Order created successfully:", response.data);
//         console.log("Selected Account:", selectedAccount);
//         navigate("/Createbill", {
//           state: { orderDetails: response.data, selectedAccount },
//         });
//       })
//       .catch((error) => {
//         console.error("Error creating order:", error.response.data);
//       });
//   };
//   const handleRemoveItem = (id) => {
//     setInvoiceDetails((prevDetails) => ({
//       ...prevDetails,
//       items: prevDetails.items.filter((item) => item.id !== id),
//     }));
//   };

//   const openModal = () => setShowModal(true);
//   const closeModal = () => setShowModal(false);

//   const handleNewCustomerChange = (e) => {
//     const { name, value } = e.target;
//     setNewCustomer((prev) => ({ ...prev, [name]: value }));
//   };

//   const addNewCustomer = async () => {
//     const token = localStorage.getItem("token");
//     try {
//       const response = await axios.post(
//         "https://luckytraders.bwsoft.in/api/customer/create/",
//         newCustomer,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       setCustomerList((prev) => [...prev, response.data.customer_data]);
//       setCustomerSearchTerm("");
//       setFilteredCustomerList([]);
//       closeModal();
//     } catch (error) {
//       console.error("Error adding customer:", error);
//     }
//   };
//   const handleSameAsBilling = (e) => {
//     if (e.target.checked) {
//       setNewCustomer((prev) => ({
//         ...prev,
//         delivery_name: prev.name,
//         delivery_address: prev.address,
//         delivery_gst_no: prev.customer_gst_no,
//       }));
//     } else {
//       setNewCustomer((prev) => ({
//         ...prev,
//         delivery_name: "",
//         delivery_address: "",
//         delivery_gst_no: "",
//       }));
//     }
//   };
//   const handlePriceChange = (itemId, newPrice) => {
//     // Update the price in the state
//     setInvoiceDetails((prev) => {
//       return {
//         ...prev,
//         items: prev.items.map((item) =>
//           item.id === itemId ? { ...item, price: newPrice } : item
//         ),
//       };
//     });
//   };
//   const calculateItemTotal = (item) => {
//     const quantity = item.quantity || 0;
//     const price = item.price || 0;
//     const gst = item.gst || 0;
//     const igst = item.igst || 0;
//     const discount = discounts[item.id]?.value || 0;
//     const isPercentageDiscount = discountType[item.id] === "percentage";

//     // Calculate discount amount
//     const discountAmount = isPercentageDiscount
//       ? (price * quantity * discount) / 100
//       : discount;

//     // Calculate total with tax and discount
//     const totalBeforeTax = price * quantity - discountAmount;
//     const taxAmount = (totalBeforeTax * (gst + igst)) / 100;

//     return totalBeforeTax + taxAmount;
//   };
//   const handleQuantityBlur = (itemId, value) => {
//     const numericValue = parseInt(value, 10);
//     if (isNaN(numericValue) || numericValue < 1) {
//       handleQuantityChange(itemId, 1);
//     }
//   };
//   return (
//     <div className="bill-template" id="tabelcontent">
//       <h1 className="invoice-title">Invoice</h1>

//       <div className="bill-header">
//         <div className="header-section">
//           <div className="search-group">
//             <div className="search-container">
//               <label htmlFor="product-search">Search Products</label>
//               <div className="search-input-wrapper">
//                 <input
//                   type="text"
//                   id="product-search"
//                   value={searchTerm}
//                   onChange={handleSearchChange}
//                   placeholder="Product name or code..."
//                   className="search-input"
//                 />
//                 <span className="search-icon">🔍</span>
//               </div>
//               {searchTerm && filteredProductList.length > 0 && (
//                 <ul className="search-dropdown">
//                   {filteredProductList.map((product) => (
//                     <li
//                       key={product.id}
//                       onClick={() => handleSelectProduct(product)}
//                     >
//                       <span className="product-name">{product.name}</span>
//                       <span className="product-price">
//                         ₹{product.retail_price || 0}
//                       </span>
//                     </li>
//                   ))}
//                 </ul>
//               )}
//             </div>

//             <div className="search-container">
//               <label htmlFor="customer-search">Search Customers</label>
//               <div className="search-input-wrapper">
//                 <input
//                   type="text"
//                   value={customerSearchTerm}
//                   onChange={handleSearchCustomer}
//                   placeholder="Customer name or mobile..."
//                   className="search-input"
//                 />
//               </div>
//               {showDropdown && customerSearchTerm && (
//                 <ul className="search-dropdown">
//                   {filteredCustomerList.map((customer) => (
//                     <li
//                       key={customer.id}
//                       onClick={() => handleSelectCustomer(customer)}
//                     >
//                       <span className="customer-name">{customer.name}</span>
//                       <span className="customer-mobile">{customer.mobile}</span>
//                     </li>
//                   ))}
//                   {filteredCustomerList.length === 0 && (
//                     <li onClick={openModal} className="add-customer">
//                       ➕ Add New Customer
//                     </li>
//                   )}
//                 </ul>
//               )}
//             </div>
//           </div>

//           <div className="outlet-info">
//             <div className="outlet-details">
//               <span className="outlet-label">Current Outlet:</span>
//               <span className="outlet-name">
//                 {selectedOutlet?.name || "No outlet selected"}
//               </span>
//             </div>
//             <button className="outlet-change-btn" onClick={handleOpenModal}>
//               Change Outlet
//             </button>
//           </div>
//         </div>
//       </div>

//       <div className="table-wrapper">
//         <table className="bill-table">
//           <thead>
//             <tr>
//               <th>#</th>
//               <th>Product</th>
//               <th>HSN</th>
//               <th>Qty</th>
//               <th>Price</th>
//               <th>GST</th>
//               <th>IGST</th>
//               <th>Discount</th>
//               <th>Total</th>
//               <th>Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {invoiceDetails.items.map((item, index) => (
//               <tr key={item.id}>
//                 <td>{index + 1}</td>
//                 <td>
//                   <div
//                     className="product-name-cell"
//                     onClick={() => toggleInputVisibility(item.id)}
//                   >
//                     {item.name}
//                     {item.quantity <= 0 && (
//                       <span className="stock-error"> (Out of stock)</span>
//                     )}
//                   </div>
//                   {visibleInputs[item.id] && (
//                     <textarea
//                       value={descriptions[item.id] || ""}
//                       onChange={(e) =>
//                         handleDescriptionChange(item.id, e.target.value)
//                       }
//                       placeholder="Add description..."
//                       className="description-input"
//                     />
//                   )}
//                 </td>
//                 <td>{item.code}</td>
//                 <td>
//                   <div className="quantity-control">
//                     <button
//                       onClick={() =>
//                         handleQuantityChange(item.id, item.quantity - 1)
//                       }
//                     >
//                       −
//                     </button>
//                     <input
//                       type="number"
//                       value={item.quantity}
//                       onChange={(e) => updateQuantity(item.id, e.target.value)}
//                       onBlur={(e) =>
//                         handleQuantityBlur(item.id, e.target.value)
//                       }
//                       min="0"
//                     />
//                     <button
//                       onClick={() =>
//                         handleQuantityChange(item.id, item.quantity + 1)
//                       }
//                     >
//                       +
//                     </button>
//                   </div>
//                 </td>
//                 <td>
//                   <input
//                     type="number"
//                     value={item.price}
//                     onChange={(e) => handlePriceChange(item.id, e.target.value)}
//                     className="price-input"
//                   />
//                 </td>
//                 <td>{item.gst || 0}%</td>
//                 <td>{item.igst || 0}%</td>
//                 <td>
//                   <div className="discount-control">
//                     <input
//                       type="text"
//                       value={discounts[item.id]?.value || ""}
//                       onChange={(e) =>
//                         handleproductDiscountChange(
//                           item.id,
//                           discountType[item.id],
//                           e.target.value
//                         )
//                       }
//                       placeholder={
//                         discountType[item.id] === "price"
//                           ? "₹ Discount"
//                           : "% Discount"
//                       }
//                     />
//                     <button onClick={() => toggleDiscountType(item.id)}>
//                       {discountType[item.id] === "price" ? "₹" : "%"}
//                     </button>
//                   </div>
//                 </td>
//                 <td className="total-cell">
//                   ₹{calculateItemTotal(item).toFixed(2)}
//                 </td>
//                 <td>
//                   <button
//                     className="remove-btn"
//                     onClick={() => handleRemoveItem(item.id)}
//                   >
//                     ×
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       <div className="summary-section">
//         <div className="summary-card">
//           <h3>Order Summary</h3>
//           <div className="summary-grid">
//             <div className="summary-item">
//               <span>Items:</span>
//               <span>
//                 {invoiceDetails.items.reduce(
//                   (acc, item) => acc + item.quantity,
//                   0
//                 )}
//               </span>
//             </div>
//             <div className="summary-item">
//               <span>Subtotal:</span>
//               <span>₹{subtotal.toFixed(2)}</span>
//             </div>
//             <div className="summary-item">
//               <span>Discount:</span>
//               <span>-₹{totalDiscount.toFixed(2)}</span>
//             </div>
//             <div className="summary-item">
//               <span>GST:</span>
//               <span>₹{totalGst.toFixed(2)}</span>
//             </div>
//             <div className="summary-item">
//               <span>IGST:</span>
//               <span>₹{totalIgst.toFixed(2)}</span>
//             </div>
//             <div className="summary-item total">
//               <span>Total Payable:</span>
//               <span>₹{totalPayable.toFixed(2)}</span>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="action-buttons">
//         <button className="btn-secondary">Cancel</button>
//         <button className="btn-secondary">Hold Bill</button>
//         <button className="btn-primary" onClick={handleOpenPaymentModal}>
//           Proceed to Payment
//         </button>
//       </div>

//       <Modal
//         show={isPaymentModalOpen}
//         onHide={() => setIsPaymentModalOpen(false)}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Make Payment</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {/* <Form.Group controlId="customerName">
//             <Form.Label>Select Customer</Form.Label>
//             <Form.Control
//               as="select"
//               value={selectedCustomer}
//               onChange={(e) => setSelectedCustomer(e.target.value)}
//             >
//               <option value="">Select Customer</option>
//               {customerList.map((customer) => (
//                 <option key={customer.id} value={customer.id}>
//                   {customer.name}
//                 </option>
//               ))}
//             </Form.Control>
//           </Form.Group> */}

//           <Form.Group controlId="accountSelect">
//             <Form.Label>Select Account</Form.Label>
//             <Form.Control
//               as="select"
//               value={selectedAccount}
//               onChange={(e) => setSelectedAccount(e.target.value)}
//             >
//               <option value="">Select account</option>
//               {Array.isArray(settinglist) && settinglist.length > 0 ? (
//                 settinglist.map((account) => (
//                   <option key={account.id} value={account.id}>
//                     {account.name} {/* Ensure you show the account name */}
//                   </option>
//                 ))
//               ) : (
//                 <option disabled>No accounts available</option>
//               )}
//             </Form.Control>
//           </Form.Group>

//           <Form.Group controlId="paymentMethod">
//             <Form.Label>Select Payment Method</Form.Label>
//             <Form.Control
//               as="select"
//               value={paymentMethod}
//               onChange={(e) => setPaymentMethod(Number(e.target.value))} // Convert to number
//             >
//               <option value="">Select Payment Method</option>
//               {paymentMethods.map((method) => (
//                 <option key={method.id} value={method.id}>
//                   {method.name}
//                 </option>
//               ))}
//             </Form.Control>
//           </Form.Group>

//           <Form.Group controlId="paidAmount">
//             <Form.Label>Paid Amount</Form.Label>
//             <Form.Control
//               type="number"
//               value={paidAmount}
//               onChange={(e) => setPaidAmount(Number(e.target.value))}
//             />
//           </Form.Group>

//           <Form.Group controlId="returnChange">
//             <Form.Label>Return Change</Form.Label>
//             <Form.Control
//               type="text"
//               value={`₹${(paidAmount - totalPayable).toFixed(2)}`}
//               readOnly
//             />
//           </Form.Group>

//           <p>Total Payable Amount: ₹{totalPayable.toFixed(2)}</p>
//           <p>Total Purchased Items: {invoiceDetails.items.length}</p>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button
//             variant="primary"
//             onClick={() =>
//               handleMakePayment(
//                 selectedOutlet,
//                 selectedCustomer,
//                 paymentMethod,
//                 totalPayable,
//                 paidAmount,
//                 invoiceDetails,
//                 selectedAccount
//               )
//             }
//           >
//             Make Payment
//           </Button>
//           <Button
//             variant="secondary"
//             onClick={() => setIsPaymentModalOpen(false)}
//           >
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       <Modal show={isModalOpen} onHide={handleCloseModal} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Select Outlet</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <ListGroup>
//             {outletList.map((outlet) => (
//               <ListGroup.Item
//                 key={outlet.id}
//                 action
//                 onClick={() => handleSelectOutlet(outlet)}
//                 className="d-flex flex-column justify-content-center align-items-center"
//               >
//                 <strong>{outlet.name}</strong>
//                 <div>{outlet.address}</div>
//                 <div>Mobile:{outlet.mobile}</div>
//               </ListGroup.Item>
//             ))}
//           </ListGroup>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       {showModal && (
//         <div
//           style={{
//             position: "fixed",
//             top: 0,
//             left: 0,
//             width: "100%",
//             height: "100%",
//             backgroundColor: "rgba(0, 0, 0, 0.5)",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             zIndex: 1000,
//           }}
//         >
//           <div
//             style={{
//               backgroundColor: "#fff",
//               borderRadius: "12px",
//               padding: "15px",
//               width: "450px",
//               boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
//             }}
//           >
//             <h2 style={{ textAlign: "center", marginBottom: "5px" }}>
//               Create Customer
//             </h2>
//             <form>
//               <div>
//                 {/* <label>Name *</label> */}
//                 <input
//                   type="text"
//                   name="name"
//                   value={newCustomer.name}
//                   onChange={handleNewCustomerChange}
//                   placeholder="Enter name"
//                   required
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     marginTop: "5px",
//                     border: "1px solid #ddd",
//                     borderRadius: "8px",
//                   }}
//                 />
//               </div>
//               <div>
//                 {/* <label>Email Address</label> */}
//                 <input
//                   type="email"
//                   name="email"
//                   value={newCustomer.email}
//                   onChange={handleNewCustomerChange}
//                   placeholder="Enter email address"
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     marginTop: "5px",
//                     border: "1px solid #ddd",
//                     borderRadius: "8px",
//                   }}
//                 />
//               </div>
//               <div>
//                 {/* <label>Mobile Number *</label> */}
//                 <input
//                   type="text"
//                   name="mobile"
//                   value={newCustomer.mobile}
//                   onChange={handleNewCustomerChange}
//                   placeholder="Enter mobile number"
//                   required
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     marginTop: "5px",
//                     border: "1px solid #ddd",
//                     borderRadius: "8px",
//                   }}
//                 />
//               </div>
//               <div>
//                 {/* <label>Address</label> */}
//                 <textarea
//                   name="address"
//                   value={newCustomer.address}
//                   onChange={handleNewCustomerChange}
//                   placeholder="Enter address"
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     marginTop: "5px",
//                     border: "1px solid #ddd",
//                     borderRadius: "8px",
//                   }}
//                 ></textarea>
//               </div>
//               <div>
//                 {/* <label>GST Number</label> */}
//                 <input
//                   type="text"
//                   name="customer_gst_no"
//                   value={newCustomer.customer_gst_no}
//                   onChange={handleNewCustomerChange}
//                   placeholder="Enter GST number"
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     marginTop: "5px",
//                     border: "1px solid #ddd",
//                     borderRadius: "8px",
//                   }}
//                 />
//               </div>
//               <h4 style={{ marginBottom: "5px" }}>Customer Delivery Address</h4>
//               <div>
//                 <input
//                   type="checkbox"
//                   id="sameAsBilling"
//                   onChange={handleSameAsBilling}
//                 />
//                 <label htmlFor="sameAsBilling">
//                   Same as billing name & address
//                 </label>
//               </div>
//               <div>
//                 {/* <label>Delivery Name</label> */}
//                 <input
//                   type="text"
//                   name="delivery_name"
//                   value={newCustomer.delivery_name}
//                   onChange={handleNewCustomerChange}
//                   placeholder="Enter delivery name"
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     marginTop: "5px",
//                     border: "1px solid #ddd",
//                     borderRadius: "8px",
//                   }}
//                 />
//               </div>
//               <div>
//                 {/* <label>Delivery Address</label> */}
//                 <textarea
//                   name="delivery_address"
//                   value={newCustomer.delivery_address}
//                   onChange={handleNewCustomerChange}
//                   placeholder="Enter delivery address"
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     marginTop: "5px",
//                     border: "1px solid #ddd",
//                     borderRadius: "8px",
//                   }}
//                 ></textarea>
//               </div>
//               <div>
//                 {/* <label>Delivery GST Number</label> */}
//                 <input
//                   type="text"
//                   name="delivery_gst_no"
//                   value={newCustomer.delivery_gst_no}
//                   onChange={handleNewCustomerChange}
//                   placeholder="Enter delivery GST number"
//                   style={{
//                     width: "100%",
//                     padding: "10px",
//                     marginTop: "5px",
//                     border: "1px solid #ddd",
//                     borderRadius: "8px",
//                   }}
//                 />
//               </div>
//               <div style={{ textAlign: "center", marginTop: "5px" }}>
//                 <button
//                   type="button"
//                   onClick={addNewCustomer}
//                   style={{
//                     backgroundColor: "#007bff",
//                     color: "#fff",
//                     padding: "10px 20px",
//                     border: "none",
//                     borderRadius: "8px",
//                     cursor: "pointer",
//                     marginRight: "10px",
//                   }}
//                 >
//                   Create
//                 </button>
//                 <button
//                   type="button"
//                   onClick={closeModal}
//                   style={{
//                     backgroundColor: "#ccc",
//                     color: "#000",
//                     padding: "10px 20px",
//                     border: "none",
//                     borderRadius: "8px",
//                     cursor: "pointer",
//                   }}
//                 >
//                   Cancel
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       )}

//       <style jsx>{`
//         .bill-template {
//           padding: 2rem;
//           max-width: 1200px;
//           margin: 0 auto;
//           background: #fff;
//           box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
//           border-radius: 12px;
//         }

//         .invoice-title {
//           text-align: center;
//           color: #2c3e50;
//           margin-bottom: 2rem;
//           font-size: 2.2rem;
//         }

//         .bill-header {
//           margin-bottom: 2rem;
//         }

//         .search-group {
//           display: grid;
//           gap: 1rem;
//           margin-bottom: 1.5rem;
//         }

//         .search-container {
//           position: relative;
//         }

//         .search-input-wrapper {
//           position: relative;
//         }

//         .search-input {
//           width: 100%;
//           padding: 0.8rem 2.5rem 0.8rem 1rem;
//           border: 2px solid #e0e0e0;
//           border-radius: 8px;
//           font-size: 1rem;
//           transition: border-color 0.3s ease;
//         }

//         .search-input:focus {
//           border-color: #3498db;
//           outline: none;
//         }

//         .search-icon {
//           position: absolute;
//           right: 1rem;
//           top: 50%;
//           transform: translateY(-50%);
//           color: #7f8c8d;
//         }

//         .search-dropdown {
//           position: absolute;
//           width: 100%;
//           max-height: 200px;
//           overflow-y: auto;
//           background: #fff;
//           border: 1px solid #e0e0e0;
//           border-radius: 8px;
//           box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
//           z-index: 100;
//           margin-top: 0.5rem;
//         }

//         .search-dropdown li {
//           padding: 0.8rem 1rem;
//           cursor: pointer;
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           transition: background 0.2s ease;
//         }

//         .search-dropdown li:hover {
//           background: #f8f9fa;
//         }

//         .outlet-info {
//           display: flex;
//           align-items: center;
//           gap: 1rem;
//           margin-top: 1rem;
//         }

//         .outlet-name {
//           font-weight: 600;
//           color: #2c3e50;
//           margin-left: 0.5rem;
//         }

//         .outlet-change-btn {
//           background: #e74c3c;
//           color: white;
//           border: none;
//           padding: 0.5rem 1rem;
//           border-radius: 6px;
//           cursor: pointer;
//           transition: background 0.2s ease;
//         }

//         .table-wrapper {
//           overflow-x: auto;
//           margin: 2rem 0;
//         }

//         .bill-table {
//           width: 100%;
//           border-collapse: collapse;
//           background: white;
//         }

//         .bill-table th {
//           background: #34495e;
//           color: white;
//           padding: 1rem;
//           font-weight: 500;
//         }

//         .bill-table td {
//           padding: 1rem;
//           border-bottom: 1px solid #ecf0f1;
//         }

//         .quantity-control {
//           display: flex;
//           align-items: center;
//           gap: 0.5rem;
//         }

//         .quantity-control button {
//           background: #3498db;
//           color: white;
//           border: none;
//           width: 32px;
//           height: 32px;
//           border-radius: 6px;
//           cursor: pointer;
//           transition: background 0.2s ease;
//         }

//         .quantity-control input {
//           width: 50px;
//           padding: 0.3rem;
//           text-align: center;
//           border: 1px solid #bdc3c7;
//           border-radius: 4px;
//         }

//         .discount-control {
//           display: flex;
//           gap: 0.3rem;
//         }

//         .discount-control button {
//           background: #95a5a6;
//           color: white;
//           border: none;
//           padding: 0 0.5rem;
//           border-radius: 4px;
//           cursor: pointer;
//         }

//         .summary-card {
//           background: #f8f9fa;
//           border-radius: 10px;
//           padding: 1.5rem;
//           margin: 2rem 0;
//         }

//         .summary-grid {
//           display: grid;
//           grid-template-columns: repeat(2, 1fr);
//           gap: 1rem;
//         }

//         .summary-item {
//           display: flex;
//           justify-content: space-between;
//           padding: 0.8rem 0;
//           border-bottom: 1px solid #ecf0f1;
//         }

//         .summary-item.total {
//           font-weight: 600;
//           color: #27ae60;
//           font-size: 1.1rem;
//         }

//         .action-buttons {
//           display: flex;
//           justify-content: flex-end;
//           gap: 1rem;
//           margin-top: 2rem;
//         }

//         .btn-primary {
//           background: #27ae60;
//           color: white;
//           padding: 0.8rem 2rem;
//           border: none;
//           border-radius: 8px;
//           cursor: pointer;
//           transition: background 0.2s ease;
//         }

//         .btn-secondary {
//           background: #95a5a6;
//           color: white;
//           padding: 0.8rem 2rem;
//           border: none;
//           border-radius: 8px;
//           cursor: pointer;
//           transition: background 0.2s ease;
//         }

//         @media (max-width: 768px) {
//           .bill-template {
//             padding: 1rem;
//           }

//           .summary-grid {
//             grid-template-columns: 1fr;
//           }

//           .action-buttons {
//             flex-direction: column;
//           }
//         }
//       `}</style>
//     </div>
//   );
// };

// export default Bill;

import {
  Table,
  Button,
  Modal,
  ListGroup,
  Form,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import {
  FaSearch,
  FaUser,
  FaSync,
  FaTimes,
  FaTimesCircle,
  FaPause,
  FaCreditCard,
} from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaChevronDown } from "react-icons/fa";
const Bill = () => {
  const [invoiceDetails, setInvoiceDetails] = useState({
    items: [],
    discountRate: 0, // default discount is 0
  });

  const [productList, setProductList] = useState([]);
  const [filteredProductList, setFilteredProductList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [outletList, setOutletList] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [paidAmount, setPaidAmount] = useState(0);
  const [returnChange, setReturnChange] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const [settinglist, setSettinglist] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [customerSearchTerm, setCustomerSearchTerm] = useState("");
  const [filteredCustomerList, setFilteredCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showDropdown, setShowDropdown] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [descriptions, setDescriptions] = useState({});
  const [visibleInputs, setVisibleInputs] = useState({});
  const [discounts, setDiscounts] = useState({}); // Track discounts for each item
  const [discountType, setDiscountType] = useState({});
  const [newCustomer, setNewCustomer] = useState({
    name: "",
    email: "",
    mobile: "",
    address: "",
    customer_gst_no: "",
    delivery_name: "",
    delivery_address: "",
    delivery_gst_no: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    // Fetch product list
    axios
      .get("https://luckytraders.bwsoft.in/api/productlist/", config)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setProductList(response.data);
          setFilteredProductList(response.data);
        }
      })
      .catch((error) => console.error("Error fetching products:", error));

    // Fetch outlet list
    axios
      .get("https://luckytraders.bwsoft.in/api/outletlist/", config)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setOutletList(response.data);
        }
      })
      .catch((error) => console.error("Error fetching outlets:", error));

    axios
      .get("https://luckytraders.bwsoft.in/api/paymentlist/", config)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setPaymentMethods(response.data.filter((method) => method.status));
          setPaymentMethod(response.data[0]?.name || "Cash"); // Set default payment method
        }
      })
      .catch((error) =>
        console.error("Error fetching payment methods:", error)
      );

    // axios
    //   .get("https://luckytraders.bwsoft.in/api/customerlist/", config)
    //   .then((response) => {
    //     if (Array.isArray(response.data)) {
    //       setCustomerList(response.data);
    //     }
    //   })
    //   .catch((error) => console.error("Error fetching customers:", error));

    axios
      .get("https://luckytraders.bwsoft.in/api/customerlist/", config)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setCustomerList(response.data);
        }
      });
    // Open the modal when the component mounts
    setIsModalOpen(true);
  }, []);

  const handleproductDiscountChange = (itemId, type, value) => {
    setDiscounts((prev) => ({
      ...prev,
      [itemId]: { type, value },
    }));
  };

  const toggleDiscountType = (itemId) => {
    setDiscountType((prev) => ({
      ...prev,
      [itemId]: prev[itemId] === "percentage" ? "price" : "percentage",
    }));
  };

  const handleSearchCustomer = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setCustomerSearchTerm(searchValue);

    if (searchValue === "") {
      setFilteredCustomerList([]);
      return;
    }

    const filteredCustomers = customerList.filter(
      (customer) =>
        customer.name.toLowerCase().includes(searchValue) ||
        customer.mobile.includes(searchValue)
    );

    setFilteredCustomerList(filteredCustomers);
  };

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer.id); // Set selected customer's ID
    setCustomerSearchTerm(`${customer.name} - ${customer.mobile}`);
    setFilteredCustomerList([]); // Reset dropdown
    setShowDropdown(false);
  };

  useEffect(() => {
    const fetchSettingList = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(
          "https://luckytraders.bwsoft.in/api/settinglist/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch settings");

        const data = await response.json();
        console.log(data); // Log to check the structure
        setSettinglist(Array.isArray(data) ? data : []); // Ensure it's an array
      } catch (error) {
        console.error("Error fetching setting list:", error);
      }
    };

    fetchSettingList();
  }, []);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);
    const filteredProducts = productList.filter(
      (product) =>
        product.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        product.code.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredProductList(filteredProducts);
  };

  const handleSelectProduct = (product) => {
    const newItem = {
      id: product.id,
      name: product.name,
      gst: product.gst,
      igst: product.igst,
      code: product.code || "N/A",
      price: parseFloat(product.retail_price) || 0,
      quantity: 1,
    };

    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      items: [...prevDetails.items, newItem],
    }));

    setSearchTerm("");
    setFilteredProductList(productList);
  };

  const handleQuantityChange = (itemId, amount) => {
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      items: prevDetails.items.map((item) =>
        item.id === itemId
          ? { ...item, quantity: Math.max(1, amount) } // Ensure valid quantity
          : item
      ),
    }));
  };

  const updateQuantity = (itemId, value) => {
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      items: prevDetails.items.map((item) =>
        item.id === itemId ? { ...item, quantity: value } : item
      ),
    }));
  };

  const handleDiscountChange = (e) => {
    const newDiscountRate = parseFloat(e.target.value) || 0;
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      discountRate: newDiscountRate,
    }));
  };
  const calculateTotalDiscount = () => {
    return invoiceDetails.items.reduce((total, item) => {
      const discount = discounts[item.id];

      if (!discount) return total; // Skip items without a discount

      let itemDiscount = 0;
      if (discount.type === "percentage") {
        // Percentage discount
        itemDiscount = item.price * item.quantity * (discount.value / 100);
      } else if (discount.type === "price") {
        // Price discount
        itemDiscount = discount.value * item.quantity;
      }

      return total + itemDiscount;
    }, 0);
  };

  // Calculate the discount amount
  const totalDiscount = calculateTotalDiscount();

  const subtotal = invoiceDetails.items.reduce(
    (acc, item) => acc + Number(item.price || 0) * Number(item.quantity || 0),
    0
  );

  const totalGst = invoiceDetails.items.reduce(
    (acc, item) => acc + ((item.gst * item.price * item.quantity) / 100 || 0),
    0
  );
  const totalIgst = invoiceDetails.items.reduce(
    (acc, item) => acc + ((item.igst * item.price * item.quantity) / 100 || 0),
    0
  );

  const discountAmount = (subtotal * invoiceDetails.discountRate) / 100;
  // const totalPayable = subtotal + totalIgst + totalGst - discountAmount;
  const totalPayable = subtotal + totalGst + totalIgst - totalDiscount;

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleSelectOutlet = (outlet) => {
    setSelectedOutlet(outlet);
    localStorage.setItem("selectedOutletId", outlet.id);
    handleCloseModal();
  };
  const handleDescriptionChange = (productId, value) => {
    setDescriptions((prev) => ({
      ...prev,
      [productId]: value,
    }));
  };

  const toggleInputVisibility = (productId) => {
    setVisibleInputs((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }));
  };
  const handleOpenPaymentModal = () => {
    setIsPaymentModalOpen(true);
  };

  const navigate = useNavigate();

  const handleMakePayment = (
    selectedOutlet,
    selectedCustomer,
    paymentMethod,
    totalPayable,
    paidAmount,
    invoiceDetails,
    selectedAccount
  ) => {
    console.log("Payment Details Received:");
    console.log("Selected Outlet:", selectedOutlet);
    console.log("Selected Customer:", selectedCustomer);
    console.log("Payment Method:", paymentMethod);
    console.log("Total Payable:", totalPayable);
    console.log("Paid Amount:", paidAmount);
    console.log("Invoice Details:", invoiceDetails);
    console.log("Selected Account:", selectedAccount);
    // Ensure you have the correct selected values
    if (!selectedOutlet || !selectedCustomer || !paymentMethod) {
      alert("Please select an outlet, customer, and payment method.");
      return;
    }
    const gst_charge = totalGst.toFixed(2);
    const igst_charge = totalIgst.toFixed(2);

    // const orderData = {
    //   card_number: "",
    //   cgst_charge: gst_charge,
    //   cgst_percent: "0.00",
    //   cheque_number: "",
    //   created_by: 1,
    //   customer_id: selectedCustomer,

    //   discount_percentage: "0.00",
    //   // discount_total: discountAmount.toFixed(2),
    //   discount_total: totalDiscount.toFixed(2),
    //   grand_total: totalPayable.toFixed(2),
    //   igst_charge: igst_charge,
    //   gst_charge: gst_charge,

    //   order_items: invoiceDetails.items.map((item) => ({
    //     igst_percent: item.igst,
    //     gst_percent: item.gst,
    //     product_id: item.id,
    //     product_name: item.name,
    //     product_code: item.code,
    //     qty: item.quantity,
    //     category_id: item.categoryId || 0,
    //     purchase_price: item.purchasePrice || "0.00",
    //     retail_price: item.price || "0.00",
    //     discount_percentage:
    //       discounts[item.id]?.type === "percentage"
    //         ? discounts[item.id]?.value || "0.00"
    //         : "0.00",
    //     discount_amount:
    //       discounts[item.id]?.type === "price"
    //         ? discounts[item.id]?.value || "0.00"
    //         : "0.00",

    //     description: descriptions[item.id] || "",
    //     total_price: "0.00",
    //   })),
    //   outlet_id: selectedOutlet.id,
    //   paid_amount: paidAmount,
    //   payment_id: paymentMethod ? parseInt(paymentMethod, 10) : 0,
    //   refund_status: 0,
    //   remark: "",
    //   return_change: (paidAmount - totalPayable).toFixed(2),
    //   sgst_charge: "0.00",
    //   sgst_percent: "0.00",
    //   status: 1,
    //   subtotal: subtotal.toFixed(2),
    //   total_items: invoiceDetails.items.length,
    //   updated_by: null,
    //   vt_status: 1,
    //   selected_account: selectedAccount,
    // };

    const orderData = {
      card_number: "",
      cgst_charge: gst_charge,
      cgst_percent: "0.00",
      cheque_number: "",
      created_by: 1,
      customer_id: selectedCustomer,

      discount_percentage: "0.00",
      discount_total: totalDiscount.toFixed(2),
      grand_total: totalPayable.toFixed(2),
      igst_charge: igst_charge,
      gst_charge: gst_charge,

      order_items: invoiceDetails.items.map((item) => {
        const basePrice =
          (Number(item.price) || 0) * (Number(item.quantity) || 0);
        const discount =
          discounts[item.id]?.type === "percentage"
            ? basePrice * (Number(discounts[item.id]?.value || 0) / 100)
            : Number(discounts[item.id]?.value || 0);
        const taxes =
          (((Number(item.gst) || 0) + (Number(item.igst) || 0)) * basePrice) /
          100;
        const totalPrice = (basePrice - discount + taxes).toFixed(2);

        return {
          igst_percent: item.igst,
          gst_percent: item.gst,
          product_id: item.id,
          product_name: item.name,
          product_code: item.code,
          qty: item.quantity,
          category_id: item.categoryId || 0,
          purchase_price: item.purchasePrice || "0.00",
          retail_price: item.price || "0.00",
          discount_percentage:
            discounts[item.id]?.type === "percentage"
              ? discounts[item.id]?.value || "0.00"
              : "0.00",
          discount_amount:
            discounts[item.id]?.type === "price"
              ? discounts[item.id]?.value || "0.00"
              : "0.00",
          description: descriptions[item.id] || "",
          total_price: totalPrice, // Pass the calculated total price
        };
      }),
      outlet_id: selectedOutlet.id,
      paid_amount: paidAmount,
      payment_id: paymentMethod ? parseInt(paymentMethod, 10) : 0,
      refund_status: 0,
      remark: "",
      return_change: (paidAmount - totalPayable).toFixed(2),
      sgst_charge: "0.00",
      sgst_percent: "0.00",
      status: 1,
      subtotal: subtotal.toFixed(2),
      total_items: invoiceDetails.items.length,
      updated_by: null,
      vt_status: 1,
      selected_account: selectedAccount,
    };

    // Get the token from localStorage
    const token = localStorage.getItem("token");

    // Set up the request config with headers
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .post(
        "https://luckytraders.bwsoft.in/api/create_order/",
        orderData,
        config
      )
      .then((response) => {
        console.log("Order created successfully:", response.data);
        console.log("Selected Account:", selectedAccount);
        navigate("/Createbill", {
          state: { orderDetails: response.data, selectedAccount },
        });
      })
      .catch((error) => {
        console.error("Error creating order:", error.response.data);
      });
  };
  const handleRemoveItem = (id) => {
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      items: prevDetails.items.filter((item) => item.id !== id),
    }));
  };

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handleNewCustomerChange = (e) => {
    const { name, value } = e.target;
    setNewCustomer((prev) => ({ ...prev, [name]: value }));
  };

  const addNewCustomer = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        "https://luckytraders.bwsoft.in/api/customer/create/",
        newCustomer,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setCustomerList((prev) => [...prev, response.data.customer_data]);
      setCustomerSearchTerm("");
      setFilteredCustomerList([]);
      closeModal();
    } catch (error) {
      console.error("Error adding customer:", error);
    }
  };
  const handleSameAsBilling = (e) => {
    if (e.target.checked) {
      setNewCustomer((prev) => ({
        ...prev,
        delivery_name: prev.name,
        delivery_address: prev.address,
        delivery_gst_no: prev.customer_gst_no,
      }));
    } else {
      setNewCustomer((prev) => ({
        ...prev,
        delivery_name: "",
        delivery_address: "",
        delivery_gst_no: "",
      }));
    }
  };
  const handlePriceChange = (itemId, newPrice) => {
    // Update the price in the state
    setInvoiceDetails((prev) => {
      return {
        ...prev,
        items: prev.items.map((item) =>
          item.id === itemId ? { ...item, price: newPrice } : item
        ),
      };
    });
  };

  return (
    <div className="bill-template p-3" id="tabelcontent">
      <h1 className="text-center mb-4 text-primary">Sales Invoice</h1>

      <Row className="mb-4 g-3">
        <Col md={8}>
          <Row className="g-3">
            <Col md={6}>
              <InputGroup>
                <InputGroup.Text className="bg-primary text-white">
                  <FaSearch />
                </InputGroup.Text>
                <Form.Control
                  type="search"
                  placeholder="Search product..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </InputGroup>
              {searchTerm && filteredProductList.length > 0 && (
                <ListGroup className="position-absolute w-100 z-3">
                  {filteredProductList.map((product) => (
                    <ListGroup.Item
                      key={product.id}
                      action
                      onClick={() => handleSelectProduct(product)}
                      className="py-2"
                    >
                      {product.name} - ₹{product.retail_price || 0}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </Col>

            <Col md={6}>
              <div className="position-relative">
                <InputGroup>
                  <InputGroup.Text className="bg-info text-white">
                    {/* Uncomment when you have FaUser imported */}
                    <FaUser />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Search customer..."
                    value={customerSearchTerm}
                    onChange={handleSearchCustomer}
                    onFocus={() => setShowDropdown(true)}
                  />
                </InputGroup>

                {showDropdown && customerSearchTerm && (
                  <ListGroup className="position-absolute w-100 z-3 mt-1 shadow">
                    {filteredCustomerList.length > 0 ? (
                      filteredCustomerList.map((customer) => (
                        <ListGroup.Item
                          key={customer.id}
                          action
                          onClick={() => handleSelectCustomer(customer)}
                          className="py-2 d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <strong>{customer.name}</strong>
                            <div className="text-muted small">
                              {customer.mobile}
                            </div>
                          </div>
                        </ListGroup.Item>
                      ))
                    ) : (
                      <ListGroup.Item
                        action
                        onClick={openModal}
                        className="py-2 text-center text-primary fw-bold"
                      >
                        + Add New Customer
                      </ListGroup.Item>
                    )}
                  </ListGroup>
                )}
              </div>
            </Col>
          </Row>
        </Col>

        <Col md={4} className="d-flex align-items-center justify-content-end">
          <div className="d-flex align-items-center gap-3">
            <div>
              <h5 className="mb-0">
                Outlet: {selectedOutlet?.name || "Not selected"}
              </h5>
              <small className="text-muted">
                {selectedOutlet?.address || ""}
              </small>
            </div>
            <Button variant="outline-primary" onClick={handleOpenModal}>
              <FaSync />
            </Button>
          </div>
        </Col>
      </Row>

      <div className="table-responsive mb-4">
        <Table bordered hover className="align-middle">
          <thead className="bg-light">
            <tr>
              <th>#</th>
              <th>Product</th>
              <th>HSN</th>
              <th className="text-center">Qty</th>
              <th>Price</th>
              <th>GST</th>
              <th>IGST</th>
              <th>Discount</th>
              <th>Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {invoiceDetails.items.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>
                  <div
                    className="text-primary cursor-pointer"
                    onClick={() => toggleInputVisibility(item.id)}
                  >
                    {item.name}
                  </div>
                  {visibleInputs[item.id] && (
                    <Form.Control
                      as="textarea"
                      rows={2}
                      value={descriptions[item.id] || ""}
                      onChange={(e) =>
                        handleDescriptionChange(item.id, e.target.value)
                      }
                      placeholder="Add description"
                      className="mt-2"
                      onClick={(e) => e.stopPropagation()} // Prevents toggleInputVisibility from triggering
                    />
                  )}
                </td>

                <td>{item.code}</td>
                <td className="text-nowrap">
                  <InputGroup className="w-75 mx-auto">
                    <Button
                      variant="outline-secondary"
                      onClick={() =>
                        handleQuantityChange(item.id, item.quantity - 1)
                      }
                    >
                      -
                    </Button>
                    <Form.Control
                      type="number"
                      min="1"
                      value={item.quantity}
                      onChange={(e) => updateQuantity(item.id, e.target.value)}
                      className="text-center"
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() =>
                        handleQuantityChange(item.id, item.quantity + 1)
                      }
                    >
                      +
                    </Button>
                  </InputGroup>
                </td>
                <td>
                  <Form.Control
                    type="number"
                    step="0.01"
                    value={item.price}
                    onChange={(e) =>
                      handlePriceChange(item.id, parseFloat(e.target.value))
                    }
                  />
                </td>
                <td>{item.gst}%</td>
                <td>{item.igst}%</td>
                <td>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      min={0}
                      step={discountType[item.id] === "price" ? 1 : 0.1}
                      placeholder={
                        discountType[item.id] === "price"
                          ? "Enter fixed amount (₹)"
                          : "Enter percentage (%)"
                      }
                      value={discounts[item.id]?.value || ""}
                      onChange={(e) => {
                        const value = Math.max(
                          0,
                          discountType[item.id] === "price"
                            ? parseFloat(e.target.value) || 0
                            : Math.min(100, parseFloat(e.target.value) || 0)
                        );
                        handleproductDiscountChange(
                          item.id,
                          discountType[item.id] || "price", // Default to price
                          value
                        );
                      }}
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() => toggleDiscountType(item.id)}
                      title={
                        discountType[item.id] === "percentage"
                          ? "Switch to Fixed Amount"
                          : "Switch to Percentage"
                      }
                    >
                      {discountType[item.id] === "percentage" ? "%" : "₹"}
                    </Button>
                  </InputGroup>
                </td>

                <td className="fw-bold">
                  ₹
                  {
                    // Calculate base total
                    (
                      (Number(item.price) || 0) * (Number(item.quantity) || 0) -
                      // Apply discount based on type
                      (discountType[item.id] === "percentage"
                        ? // For percentage: (price * quantity) * (discount% / 100)
                          (Number(item.price) || 0) *
                          (Number(item.quantity) || 0) *
                          (Number(discounts[item.id]?.value || 0) / 100)
                        : // For fixed price: direct subtraction
                          Number(discounts[item.id]?.value || 0)) +
                      // Calculate taxes
                      (((Number(item.gst) || 0) + (Number(item.igst) || 0)) *
                        (Number(item.price) || 0) *
                        (Number(item.quantity) || 0)) /
                        100
                    ).toFixed(2)
                  }
                </td>
                <td>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleRemoveItem(item.id)}
                  >
                    <FaTimes />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-end">
        <Col md={6}>
          <Table bordered className="total-table">
            <tbody>
              <tr>
                <td className="fw-bold">Subtotal:</td>
                <td className="text-end">₹{subtotal.toFixed(2)}</td>
              </tr>
              <tr>
                <td className="fw-bold">Total GST:</td>
                <td className="text-end">₹{totalGst.toFixed(2)}</td>
              </tr>
              <tr>
                <td className="fw-bold">Total IGST:</td>
                <td className="text-end">₹{totalIgst.toFixed(2)}</td>
              </tr>
              <tr>
                <td className="fw-bold">Discounts:</td>
                <td className="text-end text-danger">
                  -₹{totalDiscount.toFixed(2)}
                </td>
              </tr>
              <tr className="total-row">
                <td className="fw-bold fs-5">Total Payable:</td>
                <td className="text-end fs-5 fw-bold text-success">
                  ₹{totalPayable.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <div className="d-flex gap-3 justify-content-end mt-4">
        {/* <Button variant="outline-danger" size="lg">
          <FaTimesCircle className="me-2" />
          Cancel
        </Button>
        <Button variant="warning" size="lg">
          <FaPause className="me-2" />
          Hold Bill
        </Button> */}
        <Button variant="success" size="lg" onClick={handleOpenPaymentModal}>
          <FaCreditCard className="me-2" />
          Make Payment
        </Button>
      </div>
      {/* <p>Thank you for your business!</p> */}

      {/* <Modal
        show={isPaymentModalOpen}
        onHide={() => setIsPaymentModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Make Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="accountSelect">
            <Form.Label>Select Account</Form.Label>
            <Form.Control
              as="select"
              value={selectedAccount}
              onChange={(e) => setSelectedAccount(e.target.value)}
            >
              <option value="">Select account</option>
              {Array.isArray(settinglist) && settinglist.length > 0 ? (
                settinglist.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.name}
                  </option>
                ))
              ) : (
                <option disabled>No accounts available</option>
              )}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="paymentMethod">
            <Form.Label>Select Payment Method</Form.Label>
            <Form.Control
              as="select"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(Number(e.target.value))} // Convert to number
            >
              <option value="">Select Payment Method</option>
              {paymentMethods.map((method) => (
                <option key={method.id} value={method.id}>
                  {method.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="paidAmount">
            <Form.Label>Paid Amount</Form.Label>
            <Form.Control
              type="number"
              value={paidAmount}
              onChange={(e) => setPaidAmount(Number(e.target.value))}
            />
          </Form.Group>

          <Form.Group controlId="returnChange">
            <Form.Label>Return Change</Form.Label>
            <Form.Control
              type="text"
              value={`₹${(paidAmount - totalPayable).toFixed(2)}`}
              readOnly
            />
          </Form.Group>

          <p>Total Payable Amount: ₹{totalPayable.toFixed(2)}</p>
          <p>Total Purchased Items: {invoiceDetails.items.length}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() =>
              handleMakePayment(
                selectedOutlet,
                selectedCustomer,
                paymentMethod,
                totalPayable,
                paidAmount,
                invoiceDetails,
                selectedAccount
              )
            }
          >
            Make Payment
          </Button>
          <Button
            variant="secondary"
            onClick={() => setIsPaymentModalOpen(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal
        show={isPaymentModalOpen}
        onHide={() => setIsPaymentModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Make Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="accountSelect">
            <Form.Label>Select Account</Form.Label>
            <Form.Control
              as="select"
              value={selectedAccount}
              onChange={(e) => setSelectedAccount(e.target.value)}
            >
              <option value="">Select account</option>
              {Array.isArray(settinglist) && settinglist.length > 0 ? (
                settinglist.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.name}
                  </option>
                ))
              ) : (
                <option disabled>No accounts available</option>
              )}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="paymentMethod">
            <Form.Label>Select Payment Method</Form.Label>
            <Form.Control
              as="select"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(Number(e.target.value))}
            >
              <option value="">Select Payment Method</option>
              {paymentMethods.map((method) => (
                <option key={method.id} value={method.id}>
                  {method.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <p>Total Payable Amount: ₹{totalPayable.toFixed(2)}</p>
          <p>Total Purchased Items: {invoiceDetails.items.length}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() =>
              handleMakePayment(
                selectedOutlet,
                selectedCustomer,
                paymentMethod,
                totalPayable, // Pass totalPayable directly as paidAmount
                totalPayable, // Use totalPayable for both amount parameters
                invoiceDetails,
                selectedAccount
              )
            }
          >
            Make Payment (₹{totalPayable.toFixed(2)})
          </Button>
          <Button
            variant="secondary"
            onClick={() => setIsPaymentModalOpen(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isModalOpen} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Outlet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {outletList.map((outlet) => (
              <ListGroup.Item
                key={outlet.id}
                action
                onClick={() => handleSelectOutlet(outlet)}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <strong>{outlet.name}</strong>
                <div>{outlet.address}</div>
                <div>Mobile:{outlet.mobile}</div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderRadius: "12px",
              padding: "15px",
              width: "450px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <h2 style={{ textAlign: "center", marginBottom: "5px" }}>
              Create Customer
            </h2>
            <form>
              <div>
                {/* <label>Name *</label> */}
                <input
                  type="text"
                  name="name"
                  value={newCustomer.name}
                  onChange={handleNewCustomerChange}
                  placeholder="Enter name"
                  required
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                />
              </div>
              <div>
                {/* <label>Email Address</label> */}
                <input
                  type="email"
                  name="email"
                  value={newCustomer.email}
                  onChange={handleNewCustomerChange}
                  placeholder="Enter email address"
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                />
              </div>
              <div>
                {/* <label>Mobile Number *</label> */}
                <input
                  type="text"
                  name="mobile"
                  value={newCustomer.mobile}
                  onChange={handleNewCustomerChange}
                  placeholder="Enter mobile number"
                  required
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                />
              </div>
              <div>
                {/* <label>Address</label> */}
                <textarea
                  name="address"
                  value={newCustomer.address}
                  onChange={handleNewCustomerChange}
                  placeholder="Enter address"
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                ></textarea>
              </div>
              <div>
                {/* <label>GST Number</label> */}
                <input
                  type="text"
                  name="customer_gst_no"
                  value={newCustomer.customer_gst_no}
                  onChange={handleNewCustomerChange}
                  placeholder="Enter GST number"
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                />
              </div>
              <h4 style={{ marginBottom: "5px" }}>Customer Delivery Address</h4>
              <div>
                <input
                  type="checkbox"
                  id="sameAsBilling"
                  onChange={handleSameAsBilling}
                />
                <label htmlFor="sameAsBilling">
                  Same as billing name & address
                </label>
              </div>
              <div>
                {/* <label>Delivery Name</label> */}
                <input
                  type="text"
                  name="delivery_name"
                  value={newCustomer.delivery_name}
                  onChange={handleNewCustomerChange}
                  placeholder="Enter delivery name"
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                />
              </div>
              <div>
                {/* <label>Delivery Address</label> */}
                <textarea
                  name="delivery_address"
                  value={newCustomer.delivery_address}
                  onChange={handleNewCustomerChange}
                  placeholder="Enter delivery address"
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                ></textarea>
              </div>
              <div>
                {/* <label>Delivery GST Number</label> */}
                <input
                  type="text"
                  name="delivery_gst_no"
                  value={newCustomer.delivery_gst_no}
                  onChange={handleNewCustomerChange}
                  placeholder="Enter delivery GST number"
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                />
              </div>
              <div style={{ textAlign: "center", marginTop: "5px" }}>
                <button
                  type="button"
                  onClick={addNewCustomer}
                  style={{
                    backgroundColor: "#007bff",
                    color: "#fff",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "8px",
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                >
                  Create
                </button>
                <button
                  type="button"
                  onClick={closeModal}
                  style={{
                    backgroundColor: "#ccc",
                    color: "#000",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <style jsx>{`
        .product-dropdown {
          list-style: none;
          padding: 0;
          margin: 0;
          border: 1px solid #ccc;
          max-height: 100px;
          overflow-y: auto;
          position: absolute;
          background-color: white;
          z-index: 10;
          width: calc(30% - 20px);
        }

        .dropdown-item {
          padding: 8px 12px;
          cursor: pointer;
        }

        .dropdown-item:hover {
          background-color: #f1f1f1;
        }

        .bill-table {
          margin-top: 20px;
        }
      `}</style>
    </div>
  );
};

export default Bill;
